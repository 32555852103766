import React from 'react'
import ReactDOM from 'react-dom'

class NavParent extends React.Component {
	
	render() {
		
		return  React.createElement('div',{className: ''}, 'this.props.children');
					
	}
}

export default NavParent