import React from 'react'
import ReactDOM from 'react-dom'

class Popup extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		
		return  React.createElement('div',{className: 'overlay ' + ((this.props.makeCenter)?'makeCenter':''), onClick: () => { 
				if (this.props.blockDismiss) {

				} else {
					if (this.props.closePopup) {
						this.props.closePopup();		
					}
				}
			}},
			React.createElement('div',{className: 'container'},
				React.createElement('div',{className: 'row'},
					React.createElement('div',{className:'popup col-10 offset-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 hide_scrollbars ' + ((this.props.noScroll)?'noScroll':''), onClick: (e) => {
						e.stopPropagation();
					} },
						React.createElement('div', {className: 'popup_content '}, this.props.content)
					)
				)	
			)
		);
	}
}

export default Popup