import React from 'react'
import ReactDOM from 'react-dom'
import Navbar from 'react-bootstrap/Navbar'
import { Link } from "react-router-dom"


class SideNav extends React.Component {
	
	constructor(props) {
		super(props);

		this.state = {
			
		};
	}

	render() {
		
		let title = React.createElement('div',{key:'sn_title', className:'navTitle bg-dark h-60 mob-nav-title'}, 
			((this.props.user)? ('Welcome') : '')
			);
		let targets = [
			React.createElement('div',{key:'sn_targets', className:'content'}, 
				React.createElement(Link,{key:'sn1', to: '/', onClick: () => { this.props.toggleSideNav() },className:'row'}, 	
					React.createElement('div',{className: 'container-fluid'},
						React.createElement('div',{className: 'container-fluid padding-top-8 padding-bottom-8 hover-select ' + ((
							window.location.href.endsWith('com') ||
							window.location.href.endsWith('com/') ||
							window.location.href.endsWith('3000') || 
							window.location.href.endsWith('3000/') )?'navSelect':'')},'Home')
					)
				),
				React.createElement(Link,{key:'sn2', to: '/sessions', onClick: () => { this.props.toggleSideNav() },className:'row'}, 	
					React.createElement('div',{className: 'container-fluid'},
						React.createElement('div',{className: 'container-fluid padding-top-8 padding-bottom-8 hover-select ' + ((window.location.href.includes('sessions'))?'navSelect':'')},'Sessions')
					)
				),
				((this.props.user)? 
					React.createElement(Link,{key:'sn3', to: '/orders/all', onClick: () => { this.props.toggleSideNav() }, className:'row'}, 	
						React.createElement('div',{className: 'container-fluid'},
							React.createElement('div',{className: 'container-fluid padding-top-8 padding-bottom-8 hover-select ' + ((window.location.href.includes('orders'))?'navSelect':'')},'Orders')
						)
					)
					:
					null 
					
				),
				((this.props.user)? 
					React.createElement(Link,{key:'sn4', to: '/account', onClick: () => { this.props.toggleSideNav() }, className:'row'}, 	
						React.createElement('div',{className: 'container-fluid'},
							React.createElement('div',{className: 'container-fluid padding-top-8 padding-bottom-8 hover-select ' + ((window.location.href.includes('account'))?'navSelect':'')},'Activities & Account')
						)
					)
					:
					null 
					
				),
				((this.props.user && this.props.user.admin)? 
					React.createElement('div',{key:'sn7', onClick: () => { window.location.href = '/admin'; }, className:'row'}, 	
						React.createElement('div',{className: 'container-fluid'},
							React.createElement('div',{className: 'container-fluid padding-top-8 padding-bottom-8 hover-select '},'Admin')
						)
					)
					:
					null 
					
				),
				React.createElement(Link,{key:'sn20', to: '/pages', onClick: () => { this.props.toggleSideNav() },className:'row'}, 	
					React.createElement('div',{className: 'container-fluid'},
						React.createElement('div',{className: 'container-fluid padding-top-8 padding-bottom-8 hover-select ' + ((window.location.href.includes('pages'))?'navSelect':'')},'Tips Pages')
					)
				),
				((this.props.user)? 
				
					React.createElement('div',{key:'sn5', className:'row'}, 	
						React.createElement('div',{className: 'container-fluid'},
							React.createElement('div',{className: 'container-fluid padding-top-8 padding-bottom-8 hover-select '},
								React.createElement('div',{className:' w100', onClick: () => {this.props.sign_out() }}, 'Log Out')
							)
						)
					)
					:
					React.createElement(Link,{key:'sn6', to: '/sign_in', onClick: () => { this.props.toggleSideNav() }, className:'row'}, 	
						React.createElement('div',{className: 'container-fluid'},
							React.createElement('div',{className: 'container-fluid padding-top-8 padding-bottom-8 hover-select ' + ((
							window.location.href.endsWith('/sign_in') )?'navSelect':'')},'Log in')
						)
					)
				)
				
				
			)
		];
		let content = React.createElement('div',{key:'sn_content', className:'content'}, title, targets);
		//let exit = React.createElement('div',{className: 'exit'},String.fromCharCode(10006),'asdjkl');
		let exit = React.createElement('div',{key:'sn_exit', className: 'exit', onClick: () => { this.props.toggleSideNav() }, dangerouslySetInnerHTML: { __html: '&#215;' }});
		let sidenav = React.createElement('div',{key:'sn_sn', className: 'sidenav ' + ((this.props.display)?'display':'')},content,exit)
		return (
			
			React.createElement('div',{className: ((this.props.display)?'overlay':'')},sidenav)
		)
	}
}

export default SideNav;