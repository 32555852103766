import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import NavParent from './navParent.js';
import Home from "../packs/home.js";
import Sessions from "../packs/sessions.js";
import Pay from "../packs/pay.js";
import Orders from "../packs/orders.js";
import Link from "./link.js";
import Account from "../packs/account.js";
import Pages from "../packs/pages.js";
import Page from "../packs/page.js";
import Missing from './missing.js';
import Swal from 'sweetalert2'

import SignIn from './users/signIn.js'
import SignUp from './users/signUp.js'
import ForgotPassword from './users/forgotPassword.js'
import ResetPassword from './users/resetPassword.js'

console.log('routes');

class Routes extends React.Component {

	constructor() {
		super();
		this.toggleSideNav = this.toggleSideNav.bind(this);
		this.updateBasketValueSet = this.updateBasketValueSet.bind(this);
		this.addToBasket = this.addToBasket.bind(this);
		this.removeFromBasket = this.removeFromBasket.bind(this);
		this.toggleBasket = this.toggleBasket.bind(this);
		this.getUserAndSessions = this.getUserAndSessions.bind(this);
		this.sign_out = this.sign_out.bind(this);
		this.emptyBasket = this.emptyBasket.bind(this);
		this.checkBasketValid = this.checkBasketValid.bind(this);
		this.setBasket = this.setBasket.bind(this);

		this.state = {
			sidenav:false,
			user:null,
			basket_count: null,
			mode:'date',
			sessions:[],
			dayBins:[],
			basket:[],
			basketOpen:false,
			id_map: {},
			admin_images: {},
			leader_images: {}
		};
	}

	sign_out() {
		$.ajax({
		    url: '/users/sign_out',
		    type: "DELETE",
		    
		    beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
		    success: (data) => {
		    	console.log('data',data);
		    	this.setState({
		    		user: null
		    	});

		    	Swal.fire({
					icon: 'success',
					title: 'You have logged out successfully',
					showConfirmButton: true,
					timer: 1500
				}).then((result) => {  					
					window.location.href = '/';
				});

		    },
		    error: (error) => {
		        console.log('Error occurred!');
		    }
		});
	}

	checkBasketValid(display) {

		$.ajax({
			url: '/home/checkBasketValid',
			type: "POST",
			contentType: "application/json",
			data: JSON.stringify({
				basket: this.state.basket
			}),
			beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
		    success: (data) => {
		    	let doContinue = true;
		    	if (data.basket_filtered) {
					doContinue = false

					if (display) {
						Swal.fire(
							'Warning',
							'Classes that have already started were found in your basket and removed - please recheck your basket before paying.',
							'warning'
						)
					}
		    	}

		    	this.setState({
		        	basket: data.basket.split(',').filter(Boolean),
		        	basket_count: data.basket.split(',').filter(Boolean).length
		        });

		        window.localStorage.setItem('basket',data.basket);

		        console.log('return doContinue', doContinue);
		        
		    },
		    error: (error) => {
		        console.log('Error occurred!', error);
		        let self = this;
		        Swal.fire(
		        	'Error found in basket',
		        	error.responseJSON.message,
		        	'error'
		        );
		        if (error.responseJSON.code == 400 && error.responseJSON.hasOwnProperty('basket')) {
	            	
	            	self.setBasket(error.responseJSON.basket.join(','));

	            }

	            self.getUserAndSessions();
		    }
		});
	}

	setBasket(basket) {
		
        this.setState({
        	basket: basket.split(',').filter(Boolean),
        	basket_count: basket.split(',').filter(Boolean).length
        });

        window.localStorage.setItem('basket',basket);
    
	}

	getUser(callback) {
		$.ajax({
		    url: '/home/getUser',
		    type: "POST",
		    contentType: "application/json",
		    data: JSON.stringify({
		    }),
		    beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
		    success: (data) => {
				this.setState({
		        	user: data.user
		        }, () => {
		        	console.log('if callback');
		        	if (callback) callback();
		        });
		    },
		    error: (error) => {
		        console.log('Error occurred!', error);
		    }
		});
	}
	
	getUserAndSessions() {
		let d = new Date();
		d.setHours(0,0,0,0);

		let e = new Date(d);
		e.setHours(e.getHours() + (24 * 15))

		$.ajax({
		    url: '/home/getUserAndSessions',
		    type: "POST",
		    contentType: "application/json",
		    data: JSON.stringify({
		    	start: d,
		    	end: e
		    }),
		    beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
		    success: (data) => {
		    	console.log('data',data);
		        let id_map = {};
		        data.sessions.forEach(i => {
		        	id_map[i.id + ''] = i;
		        });
		        
		        let dayBins = [];
				let d = new Date();
				d.setHours(0,0,0,0);

				for (var i = 1; i < 16; i++) {
					let newE = new Date(d);
					newE.setHours(newE.getHours() + (i * 24));

					let newS = new Date(d);
					newS.setHours(newS.getHours() + ((i-1) * 24));

					dayBins.push({
						start:newS,
						end:newE,
						sessions: [],
						id_map:id_map
					});
				}

				console.log('dayBins',dayBins);

				dayBins.forEach(b => {
					data.sessions.forEach(s => {
						let t = new Date(s.start);
						if (t >= b.start && t < b.end) {
							b.sessions.push(s);
						}
					});	
				})
		        this.setState({
		        	sessions: data.sessions,
		        	leader_images: data.leader_images,
		        	admin_images: data.admin_images,
		        	dayBins: dayBins,
		        	id_map:id_map
		        });


		        //user

		        let basket_ids = [];
		        if (data.user) {
		        	basket_ids = data.user.basket.split(',').filter(Boolean);
		        	this.setState({
			        	user: data.user
			        });

		        	//if stored basket empty/expired, set from localstorage 
		        	//and update stored basket
			        if (data.user.basket.split(',').filter(Boolean).length < 1) {
			        	
			        	let inBasket = window.localStorage.getItem('basket');
			        	window.localStorage.setItem('basket','');

			       

			        	if (inBasket) {
			        		basket_ids = inBasket.split(',');

			        		basket_ids.forEach(id => {
			        			if (id_map[id+'']) {
			        				this.addToBasket(id);	
			        			} else {
			        				console.log('removing from basket - ', id);
			        			}
			        			
			        		});

			        		/*
			        		this.setState({
			        			basket: inBasket.split(',').filter(Boolean),
			        			basket_count: inBasket.split(',').filter(Boolean).length
			        		});
			        		*/
			        	}
			        //if stored basket available - use 
			        } else {
			        	this.setState({
			        		basket:data.user.basket.split(',').filter(Boolean),
			        		basket_count: data.user.basket.split(',').filter(Boolean).length
			        	});

			        	window.localStorage.setItem('basket',data.user.basket.split(',').filter(Boolean));
			        	
			        };


		        } else if (!data.user) {


		        	let inBasket = window.localStorage.getItem('basket');
		        	if (inBasket) {
		        		basket_ids = inBasket.split(',');
		        		let validated_basket = [];

		        		basket_ids.forEach(i => {
		        			console.log('i',i);
		        			if (id_map[i + '']) {
		        				validated_basket.push(i);
		        			}
		        		});



		        		window.localStorage.setItem('basket',validated_basket.join(','));



		        		this.setState({
		        			basket: validated_basket.filter(Boolean),
		        			basket_count: validated_basket.filter(Boolean).length
		        		});
		        	}
		        }

		    },
		    error: (error) => {
		        console.log('Error occurred!', error);
		    }

		});

	}

	componentDidMount() {
		this.getUserAndSessions();
	}

	addToBasket(id) {
		console.log('addToBasket',id);
		if (this.state.user) {
			$.ajax({
			    url: '/home/addToBasket',
			    type: "POST",
			    contentType: "application/json",
			    data: JSON.stringify({
			    	id: id
			    }),
			    beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
			    success: (data) => {
			    	console.log('add to basket success',data.basket);
			        this.setState({
			        	basket: data.basket.split(',').filter(Boolean),
			        	basket_count: data.basket.split(',').filter(Boolean).length
			        });

			        window.localStorage.setItem('basket',data.basket);
			        
			    },
			    error: (error) => {
			        console.log('Error occurred!');
			        if(callback) {
					  callback();
					}
			    }

			});	
		} else {
			let basket = window.localStorage.getItem('basket');
			if (!basket) {
				basket = [];
			} else {
				basket = basket.split(',');
			}
			basket.push(''+id);
			let b = basket.join(',');
			window.localStorage.setItem('basket',b);

			this.setState({
				basket:basket,
				basket_count:basket.length
			});	
		}
		
		
		
		
	}

	removeFromBasket(id, callback) {
		console.log('removeFromBasket',id);
		if (this.state.user) {
			$.ajax({
			    url: '/home/removeFromBasket',
			    type: "POST",
			    contentType: "application/json",
			    data: JSON.stringify({
			    	id: id
			    }),
			    beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
			    success: (data) => {
			    	console.log('remove From basket success',data);
			        this.setState({
			        	basket: data.basket.split(',').filter(Boolean),
			        	basket_count: data.basket.split(',').filter(Boolean).length
			        }, () => {
			        	console.log('state been set, now callback', callback);
			        	if(callback) {
					  		callback();
						};
			        });
					window.localStorage.setItem('basket',data.basket);
					//this is only called from pay

					

			    },
			    error: (error) => {
			        console.log('Error occurred!');
			        if(callback) {
					  callback();
					}
			    }

			});	
		} else {
			let basket = window.localStorage.getItem('basket');
			if (!basket) {
				basket = [];
			} else {
				basket = basket.split(',');
			}

			basket.splice(basket.indexOf(''+id), 1)
			
			let b = basket.join(',');
			window.localStorage.setItem('basket',b);

			this.setState({
				basket:basket,
				basket_count:basket.length
			});	
		}
		
	
		
	}

	toggleBasket() {
		console.log('toggleBasket');
		let basketOpen = !this.state.basketOpen;
		
		this.setState({
			basketOpen: basketOpen
		});
	}

	updateBasketValueSet(value, id) {
		var numOfTrue = this.state.basket.filter(function(x){ return x === id; }).length;
		let newBasket = this.state.basket.filter(function(x){ return x !== id; });
		let l = [];
		for (var i = numOfTrue.length - 1; i >= 0; i--) {
			l.push(id);
		}
		let b = newBasket.concat(l);
		this.setState({
			basket: b,
			basket_count: b.length
		});
		
	}

	emptyBasket() {
		this.setState({
			basket: [],
			basket_count: 0
		});

		window.localStorage.setItem('basket','');
	}


	toggleSideNav() {
		console.log('openSideNav');
		let sidenav = !this.state.sidenav;
		console.log('sidenav',sidenav);
		this.setState({
			sidenav: sidenav
		});
		
	}

	render() {
		let props = this.state;
		return React.createElement(Router, {},
			React.createElement(Switch, {},
				//Route.createElement(Route, {path:'/', component: NavParent},
					React.createElement(Route, {path: '/', exact:true, render: (props) => {
							return React.createElement(Home, {
								sidenav: this.state.sidenav,
								user:this.state.user,
								sign_out: this.sign_out,
								mode:this.state.mode,
								sessions:this.state.sessions,
								admin_images: this.state.admin_images,
								leader_images: this.state.leader_images,
								dayBins:this.state.dayBins,
								basket:this.state.basket,
								basketOpen:this.state.basketOpen,
								basket_count: this.state.basket_count,
								id_map: this.state.id_map,
								toggleSideNav: this.toggleSideNav,
								toggleBasket: this.toggleBasket,
								addToBasket: this.addToBasket,
								getUserAndSessions: this.getUserAndSessions,
								removeFromBasket: this.removeFromBasket
							});
						}
						 
					}),
					React.createElement(Route, {path: '/sessions', exact:true, render: (props) => {
							return React.createElement(Sessions, {
								sidenav: this.state.sidenav,
								user:this.state.user,
								sign_out: this.sign_out,
								mode:this.state.mode,
								sessions:this.state.sessions,
								admin_images: this.state.admin_images,
								leader_images: this.state.leader_images,
								dayBins:this.state.dayBins,
								basket:this.state.basket,
								basketOpen:this.state.basketOpen,
								basket_count: this.state.basket_count,
								id_map: this.state.id_map,
								toggleSideNav: this.toggleSideNav,
								toggleBasket: this.toggleBasket,
								addToBasket: this.addToBasket,
								getUserAndSessions: this.getUserAndSessions,
								removeFromBasket: this.removeFromBasket
							});
						}
						 
					}),
					React.createElement(Route, {path: '/pay', exact:true, render: (props) => {
							if (this.state.user) {
								return React.createElement(Pay, {
									sidenav: this.state.sidenav,
									user:this.state.user,
									sign_out: this.sign_out,
									mode:this.state.mode,
									sessions:this.state.sessions,
									admin_images: this.state.admin_images,
									leader_images: this.state.leader_images,
									dayBins:this.state.dayBins,
									basket:this.state.basket,
									basketOpen:this.state.basketOpen,
									basket_count: this.state.basket_count,
									id_map: this.state.id_map,
									toggleSideNav: this.toggleSideNav,
									toggleBasket: this.toggleBasket,
									addToBasket: this.addToBasket,
									getUserAndSessions: this.getUserAndSessions,
									removeFromBasket: this.removeFromBasket,
									emptyBasket: this.emptyBasket,
									checkBasketValid: this.checkBasketValid,
									setBasket: this.setBasket
								});	
							} else {

								let b = this.state.basket.join(',');
								window.localStorage.setItem('basket',b);

								window.location.href = '/';
								return null;
							}
							
						}
						 
					}),
					React.createElement(Route, {path: '/orders/:order_num?', exact:true, render: (props) => {
							if (this.state.user) {
								return React.createElement(Orders, {
									sidenav: this.state.sidenav,
									user:this.state.user,
									sign_out: this.sign_out,
									mode:this.state.mode,
									sessions:this.state.sessions,
									admin_images: this.state.admin_images,
									leader_images: this.state.leader_images,
									dayBins:this.state.dayBins,
									basket:this.state.basket,
									basketOpen:this.state.basketOpen,
									basket_count: this.state.basket_count,
									id_map: this.state.id_map,
									toggleSideNav: this.toggleSideNav,
									toggleBasket: this.toggleBasket,
									addToBasket: this.addToBasket,
									getUserAndSessions: this.getUserAndSessions,
									removeFromBasket: this.removeFromBasket
								});
							} else {

								let b = this.state.basket.join(',');
								window.localStorage.setItem('basket',b);

								window.location.href = '/';
								return null;
							}
						}
					}),
					React.createElement(Route, {path: '/sign_in', exact:true, render: (props) => {
						if (this.state.user) {
							window.location.href = '/';
							return null;
						} else {
							return React.createElement(SignIn, {
								sidenav: this.state.sidenav,
								user:this.state.user,
								sign_out: this.sign_out,
								mode:this.state.mode,
								sessions:this.state.sessions,
								admin_images: this.state.admin_images,
								leader_images: this.state.leader_images,
								dayBins:this.state.dayBins,
								basket:this.state.basket,
								basketOpen:this.state.basketOpen,
								basket_count: this.state.basket_count,
								id_map: this.state.id_map,
								toggleSideNav: this.toggleSideNav,
								toggleBasket: this.toggleBasket,
								addToBasket: this.addToBasket,
								getUserAndSessions: this.getUserAndSessions,
								getUser: this.getUser,
								removeFromBasket: this.removeFromBasket
							});
						}
					}}),
					
					React.createElement(Route, {path: '/sign_up', exact:true, render: (props) => {
						if (this.state.user) {
							window.location.href = '/';
							return null;
						} else {
							return React.createElement(SignUp, {
								sidenav: this.state.sidenav,
								user:this.state.user,
								sign_out: this.sign_out,
								mode:this.state.mode,
								sessions:this.state.sessions,
								admin_images: this.state.admin_images,
								leader_images: this.state.leader_images,
								dayBins:this.state.dayBins,
								basket:this.state.basket,
								basketOpen:this.state.basketOpen,
								basket_count: this.state.basket_count,
								id_map: this.state.id_map,
								toggleSideNav: this.toggleSideNav,
								toggleBasket: this.toggleBasket,
								addToBasket: this.addToBasket,
								getUserAndSessions: this.getUserAndSessions,
								getUser: this.getUser,
								removeFromBasket: this.removeFromBasket
							});
						}
					}}),

					React.createElement(Route, {path: '/forgot_password', exact:true, render: (props) => {
						if (this.state.user) {
							window.location.href = '/';
							return null;
						} else {
							return React.createElement(ForgotPassword, {
								sidenav: this.state.sidenav,
								user:this.state.user,
								sign_out: this.sign_out,
								mode:this.state.mode,
								sessions:this.state.sessions,
								admin_images: this.state.admin_images,
								leader_images: this.state.leader_images,
								dayBins:this.state.dayBins,
								basket:this.state.basket,
								basketOpen:this.state.basketOpen,
								basket_count: this.state.basket_count,
								id_map: this.state.id_map,
								toggleSideNav: this.toggleSideNav,
								toggleBasket: this.toggleBasket,
								addToBasket: this.addToBasket,
								getUserAndSessions: this.getUserAndSessions,
								getUser: this.getUser,
								removeFromBasket: this.removeFromBasket
							});
						}
					}}),

					React.createElement(Route, {path: '/password/edit', exact:true, render: (props) => {

						return React.createElement(ResetPassword, {
							sidenav: this.state.sidenav,
							user:this.state.user,
							sign_out: this.sign_out,
							mode:this.state.mode,
							sessions:this.state.sessions,
							admin_images: this.state.admin_images,
							leader_images: this.state.leader_images,
							dayBins:this.state.dayBins,
							basket:this.state.basket,
							basketOpen:this.state.basketOpen,
							basket_count: this.state.basket_count,
							id_map: this.state.id_map,
							toggleSideNav: this.toggleSideNav,
							toggleBasket: this.toggleBasket,
							addToBasket: this.addToBasket,
							getUserAndSessions: this.getUserAndSessions,
							getUser: this.getUser,
							removeFromBasket: this.removeFromBasket
						});

					}}),

					React.createElement(Route, {path: '/file/:link', exact:true, render: (props) => {
							
							return React.createElement(Link, {
								link: props.match.params.link,
								//name: props.match.params.name,
								sidenav: this.state.sidenav,
								user:this.state.user,
								sign_out: this.sign_out,
								mode:this.state.mode,
								sessions:this.state.sessions,
								admin_images: this.state.admin_images,
								leader_images: this.state.leader_images,
								dayBins:this.state.dayBins,
								basket:this.state.basket,
								basketOpen:this.state.basketOpen,
								basket_count: this.state.basket_count,
								id_map: this.state.id_map,
								toggleSideNav: this.toggleSideNav,
								toggleBasket: this.toggleBasket,
								addToBasket: this.addToBasket,
								getUserAndSessions: this.getUserAndSessions,
								getUser: this.getUser,
								removeFromBasket: this.removeFromBasket
							});
						
						}
						 
					}),

					React.createElement(Route, {path: '/account', exact:true, render: (props) => {
						if (this.state.user) {	
							return React.createElement(Account, {
								sidenav: this.state.sidenav,
								user:this.state.user,
								sign_out: this.sign_out,
								mode:this.state.mode,
								sessions:this.state.sessions,
								admin_images: this.state.admin_images,
								leader_images: this.state.leader_images,
								dayBins:this.state.dayBins,
								basket:this.state.basket,
								basketOpen:this.state.basketOpen,
								basket_count: this.state.basket_count,
								id_map: this.state.id_map,
								toggleSideNav: this.toggleSideNav,
								toggleBasket: this.toggleBasket,
								addToBasket: this.addToBasket,
								getUserAndSessions: this.getUserAndSessions,
								getUser: this.getUser,
								removeFromBasket: this.removeFromBasket
							});

						} else {
							window.location.href = '/';
							return null;
						}
						
						}
						 
					}),

					React.createElement(Route, {path: '/pages', exact:true, render: (props) => {
						
							return React.createElement(Pages, {
								sidenav: this.state.sidenav,
								user:this.state.user,
								sign_out: this.sign_out,
								mode:this.state.mode,
								sessions:this.state.sessions,
								admin_images: this.state.admin_images,
								leader_images: this.state.leader_images,
								dayBins:this.state.dayBins,
								basket:this.state.basket,
								basketOpen:this.state.basketOpen,
								basket_count: this.state.basket_count,
								id_map: this.state.id_map,
								toggleSideNav: this.toggleSideNav,
								toggleBasket: this.toggleBasket,
								addToBasket: this.addToBasket,
								getUserAndSessions: this.getUserAndSessions,
								getUser: this.getUser,
								removeFromBasket: this.removeFromBasket
							});

						
						
						}
						 
					}),
					React.createElement(Route, {path: '/pages/:url', exact:true, render: (props) => {
							return React.createElement(Page, {
								sidenav: this.state.sidenav,
								url: props.match.params.url,
								user:this.state.user,
								sign_out: this.sign_out,
								mode:this.state.mode,
								sessions:this.state.sessions,
								admin_images: this.state.admin_images,
								leader_images: this.state.leader_images,
								dayBins:this.state.dayBins,
								basket:this.state.basket,
								basketOpen:this.state.basketOpen,
								basket_count: this.state.basket_count,
								id_map: this.state.id_map,
								toggleSideNav: this.toggleSideNav,
								toggleBasket: this.toggleBasket,
								addToBasket: this.addToBasket,
								getUserAndSessions: this.getUserAndSessions,
								getUser: this.getUser,
								removeFromBasket: this.removeFromBasket
							});
					}}),

					React.createElement(Route, {render: (props) => {
							window.location.href = '/';
							return null;
						}
					})
					/*
					React.createElement(Route, {path: '/users/', render: (props) => {
							window.location.href = '/';
							return null;	
						}
					}),



					React.createElement(Route, {path:'/l', component: Missing } ),
					React.createElement(Route, {component: Missing } )
					*/
				//)
				
			)
		);
	}
}

export default Routes;
/*
export default (
  <Router>
    <Switch>
      <Route path="/" exact component={Home} />
    </Switch>
  </Router>
);
*/