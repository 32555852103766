import React from 'react'
import ReactDOM from 'react-dom'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'

import SideNav from '../src/sidenav.js'
import SideNavFixed from '../src/sidenavfixed.js'
import Nav from '../src/nav.js'
import DateView from '../src/home/dateView.js'
import SessionView from '../src/home/sessionView.js'
import Popup from '../src/popup.js'
import Basket from '../src/basket.js'
import Footer from '../src/footer.js'
import {Helmet} from "react-helmet";
import { Link } from "react-router-dom";

//const PayPalButton = paypal.Buttons.driver("react", { React, ReactDOM });

class Sessions extends React.Component {
	constructor() {
		super();
		this.setSelectedDate = this.setSelectedDate.bind(this);
		this.setKey = this.setKey.bind(this);

		this.state = {
			selectedDate: 0
		}
	}

	componentDidMount() {

	}

	setKey(key) {
		this.setState({
			activeKey: key
		});
	}

	setSelectedDate(option) {
		this.setState({
			selectedDate:option
		});
	}

	render() {

		let openBasket = React.createElement(Basket, {
			user: this.props.user,
			basketOpen: this.props.basketOpen,
			basket_count: this.props.basket_count,
			basket: this.props.basket,
			id_map: this.props.id_map,
			toggleBasket: this.props.toggleBasket,
			addToBasket: this.props.addToBasket,
			removeFromBasket: this.props.removeFromBasket,
			linkString: ((this.props.user)?'Go to Checkout':'Log in before checking out'),
			link: ((this.props.user)?'/pay':'/sign_in')
		});

		let tabs = React.createElement(Tabs, {
			id:'tabs', 
			activeKey: this.state.activeKey, 
			className: 'tabs nav-fill',
			onSelect: (k) => { this.setKey(k) }
		},
			React.createElement('div', {eventKey: 'date', title:'By Day'}, React.createElement(DateView,{basket:this.props.basket, removeFromBasket: this.props.removeFromBasket, addToBasket:this.props.addToBasket, sessions:this.props.sessions, dayBins:this.props.dayBins, setSelectedDate:this.setSelectedDate, selectedDate:this.state.selectedDate, user: this.props.user, admin_images: this.props.admin_images, leader_images: this.props.leader_images})),
			React.createElement('div', {eventKey: 'session', title:'By Class Type'}, React.createElement(SessionView,{basket:this.props.basket, removeFromBasket: this.props.removeFromBasket, updateBasketValueSet: this.props.updateBasketValueSet, addToBasket:this.props.addToBasket, sessions:this.props.sessions, user: this.props.user, admin_images: this.props.admin_images, leader_images: this.props.leader_images}))
		);

		let sidenav = React.createElement(SideNav,{display:this.props.sidenav, sign_out: this.props.sign_out, user:this.props.user, toggleSideNav:this.props.toggleSideNav});
		let nav = React.createElement(Nav,{toggleSideNav:this.props.toggleSideNav, sign_out: this.props.sign_out, user:this.props.user, basket:this.props.basket, basket_count:this.props.basket_count, toggleBasket:this.props.toggleBasket});
		let page = React.createElement('div',{},
			React.createElement('div',{className:'row no-gutters sortScroll'},
				React.createElement('div',{className:'d-xl-block d-lg-block d-md-block d-none col-lg-2 col-md-3 mht bs'},
					React.createElement(SideNavFixed,{sign_out: this.props.sign_out, user: this.props.user})
				),
				React.createElement('div',{className:'pl-3 pr-3 pt-20 pb-20 col-12 col-md-9 offset-lg-1 col-lg-8 mht'},
					tabs
				),
				React.createElement(Footer, {basket_count: this.props.basket_count})
			)
		);

		let footer = null;

		let helmet = React.createElement(Helmet, {},
        	React.createElement('title',{}, 'Activities and Sessions - Inclusive Online Activities'),
        	React.createElement('meta',{name:'description', content:'We run sessions throughout the week for adults and young people with learning disabilities.'})
        )

		let floating_checkout = React.createElement(Link,{to: ((this.props.user)?'/pay':'/sign_in'), className:'bg-800080 floating_checkout ' + ((this.props.basket_count > 0)?'activated':'deactivated')}, ((this.props.user)?'Go to Checkout':'Log in before checking out'))


		return (
			
			React.createElement('div',{className:'no-overflow'},helmet,nav,sidenav,page,footer,openBasket, floating_checkout)
		)
	}
}

export default Sessions;