import React from 'react'
import ReactDOM from 'react-dom'
import DateSlider from './dateSlider.js'
import SessionItem from './sessionItem.js'

import Card from 'react-bootstrap/Card'
import { Link } from "react-router-dom";

class DateView extends React.Component {
	render() {
		
		let ds = {};
		if (this.props.dayBins[this.props.selectedDate] && this.props.dayBins[this.props.selectedDate].sessions ) {
			for (var i = 0; i < 25; i++) {
				[0,15,30,45].forEach(min => {


					this.props.dayBins[this.props.selectedDate].sessions.forEach(s => {

						let t = new Date(s.start);
						
						if (t.getHours() == i && t.getMinutes() == min) {
							if (!ds['T'+i+'-'+min]) {
								ds['T'+i+'-'+min] = []
							}
							ds['T'+i+'-'+min].push(s);		
						}
					});

				});
			}
		}


		let t_cards = [];
		
		let timesObj = {
			'T0-0': 'Start 12 am',
			'T0-15': 'Start 12:15am',
			'T0-30': 'Start 12:30am',
			'T0-45': 'Start 12:45am',

			'T1-0': 'Start 1am',
			'T1-15': 'Start 1:15am',
			'T1-30': 'Start 1:30am',
			'T1-45': 'Start 1:45am',

			'T2-0': 'Start 2am',
			'T2-15': 'Start 2:15am',
			'T2-30': 'Start 2:30am',
			'T2-45': 'Start 2:45am',

			'T3-0': 'Start 3am',
			'T3-15': 'Start 3:15am',
			'T3-30': 'Start 3:30am',
			'T3-45': 'Start 3:45am',

			'T4-0': 'Start 4am',
			'T4-15': 'Start 4:15am',
			'T4-30': 'Start 4:30am',
			'T4-45': 'Start 4:45am',

			'T5-0': 'Start 5am',
			'T5-15': 'Start 5:15am',
			'T5-30': 'Start 5:30am',
			'T5-45': 'Start 5:45am',

			'T6-0': 'Start 6am',
			'T6-15': 'Start 6:15am',
			'T6-30': 'Start 6:30am',
			'T6-45': 'Start 6:45am',

			'T7-0': 'Start 7am',
			'T7-15': 'Start 7:15am',
			'T7-30': 'Start 7:30am',
			'T7-45': 'Start 7:45am',

			'T8-0': 'Start 8am',
			'T8-15': 'Start 8:15am',
			'T8-30': 'Start 8:30am',
			'T8-45': 'Start 8:45am',

			'T9-0': 'Start 9am',
			'T9-15': 'Start 9:15am',
			'T9-30': 'Start 9:30am',
			'T9-45': 'Start 9:45am',

			'T10-0': 'Start 10am',
			'T10-15': 'Start 10:15am',
			'T10-30': 'Start 10:30am',
			'T10-45': 'Start 10:45am',

			'T11-0': 'Start 11am',
			'T11-15': 'Start 11:15am',
			'T11-30': 'Start 11:30am',
			'T11-45': 'Start 11:45am',

			'T12-0': 'Start 12pm',
			'T12-15': 'Start 12:15pm',
			'T12-30': 'Start 12:30pm',
			'T12-45': 'Start 12:45pm',

			'T13-0': 'Start 1pm',
			'T13-15': 'Start 1:15pm',
			'T13-30': 'Start 1:30pm',
			'T13-45': 'Start 1:45pm',

			'T14-0': 'Start 2pm',
			'T14-15': 'Start 2:15pm',
			'T14-30': 'Start 2:30pm',
			'T14-45': 'Start 2:45pm',


			'T15-0': 'Start 3pm',
			'T15-15': 'Start 3:15pm',
			'T15-30': 'Start 3:30pm',
			'T15-45': 'Start 3:45pm',

			'T16-0': 'Start 4pm',
			'T16-15': 'Start 4:15pm',
			'T16-30': 'Start 4:30pm',
			'T16-45': 'Start 4:45pm',

			'T17-0': 'Start 5pm',
			'T17-15': 'Start 5:15pm',
			'T17-30': 'Start 5:30pm',
			'T17-45': 'Start 5:45pm',

			'T18-0': 'Start 6pm',
			'T18-15': 'Start 6:15pm',
			'T18-30': 'Start 6:30pm',
			'T18-45': 'Start 6:45pm',

			'T19-0': 'Start 7pm',
			'T19-15': 'Start 7:15pm',
			'T19-30': 'Start 7:30pm',
			'T19-45': 'Start 7:45pm',

			'T20-0': 'Start 8pm',
			'T20-15': 'Start 8:15pm',
			'T20-30': 'Start 8:30pm',
			'T20-45': 'Start 8:45pm',

			'T21-0': 'Start 9pm',
			'T21-15': 'Start 9:15pm',
			'T21-30': 'Start 9:30pm',
			'T21-45': 'Start 9:45pm',

			'T22-0': 'Start 10pm',
			'T22-15': 'Start 10:15pm',
			'T22-30': 'Start 10:30pm',
			'T22-45': 'Start 10:45pm',

			'T23-0': 'Start 11pm',
			'T23-15': 'Start 11:15pm',
			'T23-30': 'Start 11:30pm',
			'T23-45': 'Start 11:45pm'


		}

		let spaces_selects = [
			React.createElement('option', {value: null}, 'Choose...')
		];
		for (var i = 1; i < 21; i++) {
			spaces_selects.push(
				React.createElement('option', {value: i}, i)	
			);
		}

		
		Object.keys(ds).forEach(i => {
			let items = [];
			ds[i].forEach(item => {
				let myCount = this.props.basket.filter(x => x == item.id).length;
				let spaces = item.spaces - item.booked_spaces - myCount;
				let spaces_text = '';
				if (spaces == 0 && myCount > 0) {
					spaces_text = "You've got the last space!";
				} else if (spaces == 0) {
					spaces_text = "No spaces left";
				} else if (spaces > 1 && spaces < 4) {
					spaces_text = spaces + ' spaces remaining';
				} else if (spaces == 1) {
					spaces_text = "Only " + spaces + " space remaining";
				}

				let prebooked = null;
				if (this.props.user) {
					let attendees = item.attendees.split(',');
					var count = {};
					attendees.forEach(number => count[number] = (count[number] || 0) +1);

					if (count[this.props.user.id] > 0) {
						prebooked = React.createElement('div', {className:'green px12'}, 
							'You have ' + count[this.props.user.id] + ' ' +
							((count[this.props.user.id] > 1)?'spaces':'space') + 
							' booked on this session'
						)
					}
				}
				
				items.push(
					React.createElement('div',{key: item.id + '_cb', className:'card-body mb-card'},
						React.createElement('h5',{key: item.id + '_ct', className:'card-title margin-bottom-0 fw-700 text-align-center padding-bottom-5'},item.title),
						React.createElement('div',{className:'row'},
							React.createElement('div',{className:'col-12 col-md-5'},
								React.createElement('div',{className:'row padding-bottom-10 padding-top-5'},
									React.createElement('div',{className:'col-6 text-align-center'},
										React.createElement('img',{className:'maxw-100 maxh-250', src:this.props.leader_images['a'+item.leader]})

									),
									React.createElement('div',{className:'col-6 text-align-center'},

										React.createElement('img',{className:'maxw-100 maxh-250', src:this.props.admin_images['a'+item.admin]})

									)

								)
								
							),
							React.createElement('div',{className:'col-12 col-md-7'},


								React.createElement('div',{key: item.id + '_cd1', className:'card-text'},item.description),
								React.createElement('div',{key: item.id + '_cp', className:'card-text'}, 'Price: £'+parseFloat(item.price).toFixed(2) + ' per person'),
								React.createElement('div',{key: item.id + '_cd2', className:'card-text'}, 'Length: ' + item.duration + ' minutes'),
								((this.props.basket.indexOf(item.id.toString()) < 0)?
									React.createElement('div',{
										key: item.id + '_bb',
										className:'btn ' + ((spaces > 0)?'btn-primary':'btn-secondary disabled') + ' mt-8', 
										onClick:() => { 
											if (spaces > 0) {
												this.props.addToBasket(item.id) 	
											}
										}
									},'Add to basket')
									:
									React.createElement('div',{key: item.id + '_cbd', className:'add mt-8'},
										React.createElement('div',{className:'btn btn-primary mr-10',onClick: () => {
											this.props.removeFromBasket(item.id)
										}},' - '),
										this.props.basket.filter(function(x){ return x === item.id.toString(); }).length + ' in basket ',
										React.createElement('div',{className:'btn ' + ((spaces > 0)?'btn-primary':'btn-secondary disabled') + ' ml-10',onClick: () => {
											if (spaces > 0) {
												this.props.addToBasket(item.id)
											}
										}},' + ')
									)
								)
							)
						),
						React.createElement('div',{className:'spaces ' + ((spaces < 2)?'sad_booking':'happy_booking')}, 
							spaces_text
						),
						prebooked
					)
				);
			})
			t_cards.push(
				React.createElement('div',{key: i+'card_dv', className:'card mt-8'},
					React.createElement('h5',{key: i+'card_header_dv', className:'card-header bg-light-purple fw-700'}, timesObj[i]),
					items
				)
			)
		});

		console.log('t_cards', t_cards);
		if (t_cards.length < 1) {
			t_cards.push(React.createElement('div',{className:'row'},
				React.createElement('div',{className:'col-10 offset-1'},
					React.createElement('div',{className:'text-align-center'},'No classes scheduled on this day sadly - please try another date!')
				)
			));
		} 

		t_cards.push(React.createElement('div',{className:'row'},
			React.createElement('div',{className:'col-10 offset-1 text-align-center pt-20'},
				'For instructions on how to book a session, please check out our ', 
				React.createElement(Link, {to:'/'},'home page'),
				'.'
			))
		);
		
		
				
		let dateContent = React.createElement('div',{className:'dateContent'},
			
			React.createElement(DateSlider,{setSelectedDate:this.props.setSelectedDate, selectedDate:this.props.selectedDate}),

			React.createElement('div',{className:'timecards mt-8'}, t_cards)
		);

		return  React.createElement('div',{className:'dateContainer'},dateContent);
	}
}

export default DateView