import React from 'react'
import ReactDOM from 'react-dom'

import SideNav from '../src/sidenav.js'
import SideNavFixed from '../src/sidenavfixed.js'
import Nav from '../src/nav.js'

import Popup from '../src/popup.js'
import Basket from '../src/basket.js'

import Order from '../src/orders/order.js'
import {Helmet} from "react-helmet";
import Footer from '../src/footer.js'

class Orders extends React.Component {
	constructor() {
		super();

		this.state = {
			orders: [],
			isLoading: true
		}
	}

	componentDidMount() {
		$.ajax({
		    url: '/home/getUserOrders',
		    type: "POST",
		    contentType: "application/json",
		    data: JSON.stringify({
		    	user: this.props.user
		    }),
		    beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
		    success: (data) => {
		    	console.log('data',data);
		    	this.setState({
		    		isLoading: false,
		    		orders: data.orders
		    	});
		    },
		    error: (error) => {
		        console.log('Error occurred!');
		        this.setState({
		    		isLoading: false
		    	});
		    }
		});		
	}

	render() {

		let openBasket = React.createElement(Basket, {
			user: this.props.user,
			basketOpen: this.props.basketOpen,
			basket_count: this.props.basket_count,
			basket: this.props.basket,
			id_map: this.props.id_map,
			toggleBasket: this.props.toggleBasket,
			linkString: ((this.props.user)?'Go to Checkout':'Log in before checking out'),
			link: ((this.props.user)?'/pay':'/sign_in')
		});


		let orders = [];


		this.state.orders.forEach(order => {
			

			orders.push(
				React.createElement(Order, {order:order})
			)
		});

		let sidenav = React.createElement(SideNav,{display:this.props.sidenav, sign_out: this.props.sign_out, user:this.props.user, toggleSideNav:this.props.toggleSideNav});
		let nav = React.createElement(Nav,{toggleSideNav:this.props.toggleSideNav, sign_out: this.props.sign_out, user:this.props.user, basket:this.props.basket, basket_count:this.props.basket_count, toggleBasket:this.props.toggleBasket});
		let page = React.createElement('div',{},
			
			React.createElement('div',{className:'row no-gutters sortScroll'},
				React.createElement('div',{className:'d-xl-block d-lg-block d-md-block d-none col-lg-2 col-md-3 mht bs'},
					React.createElement(SideNavFixed,{sign_out: this.props.sign_out, user: this.props.user})
				),
				
				React.createElement('div',{className:'pl-3 pr-3 pt-20 pb-20 col-12 col-md-9 offset-lg-1 col-lg-8 mht'},
					React.createElement('h4',{className:'text-center'},
						'Orders'
					),
					orders
				),
				React.createElement(Footer, {})
				
			)

		);

		let helmet = React.createElement(Helmet, {},
        	React.createElement('title',{}, 'Orders - Inclusive Online Activities'),
        	React.createElement('meta',{name:'description', content:'See all your past orders with Inclusive Online Activities'})
        )

		return (
			
			React.createElement('div',{className:'no-overflow'},helmet, nav,sidenav,page,openBasket)
		)
	}

}

export default Orders;