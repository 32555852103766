import React from 'react'
import ReactDOM from 'react-dom'

class DateSlider extends React.Component {
	render() {

		let d = new Date();
		d.setHours(0,0,0,0);

		let dt = new Date(d);
		dt.setHours(dt.getHours() + 24)

		let dateArray = [{
			start:d,
			end:dt,
			day:'Today',
			date: d.getDate(),
			month:d.getMonth(),
			option:0,
			selected: (this.props.selectedDate == 0)
		}];

		for (var i = 1; i < 15; i++) {
			let newD = new Date(d);
			newD.setHours(newD.getHours() + (i * 24));
			let newDT = new Date(d);
			newDT.setHours(newDT.getHours() + (i * 24) + 24)
			dateArray.push({
				start:newD,
				end:newDT,
				day: newD.getDay(),
				date:newD.getDate(),
				month:newD.getMonth(),
				option:i,
				selected: (this.props.selectedDate == i)
			});
		}

		let dateElements = []
		let days = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
		let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];


		dateArray.forEach(d => {
			dateElements.push(
				React.createElement('div',{key: JSON.stringify(d)+'_slider', className:'date',onClick:() => { this.props.setSelectedDate(d.option) }},
					React.createElement('div',{key: JSON.stringify(d)+'title', className:'title'}, (days[d.day])?days[d.day]:d.day),
					React.createElement('div',{key: JSON.stringify(d)+'details', className:'details'},
						React.createElement('div',{key: JSON.stringify(d)+'day', className:'day'},d.date),
						React.createElement('div',{key: JSON.stringify(d)+'month', className:'month'},months[d.month])
					),
					((d.selected)?React.createElement('div',{key: JSON.stringify(d)+'triangle', className:'triangle'}):null)
					
				)

			);
		})


		let sliderContent = React.createElement('div',{className:'sliderContent'},dateElements);

		return  React.createElement('div',{className:'sliderContainer'},sliderContent);
	}
}

export default DateSlider