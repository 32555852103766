import React from 'react'
import ReactDOM from 'react-dom'

class Order extends React.Component {
	constructor() {
		super();

		this.state = {

		}
	}


	render() {
		let date = new Date(this.props.order.date);

		let sessions = [];
		let tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
		let discounts = []
		try{
			JSON.parse(this.props.order.discounts).forEach(d => {
				console.log('discounts 22',d);	
				discounts.push([
					React.createElement('div',{className:'col-6'},

						'Discount: '+ d.name
					),
					React.createElement('div',{className:'col-2'}, null
						
					),
					React.createElement('div',{className:'col-2'}, null
						
					),
					React.createElement('div',{className:'col-2 padding-lr-5 text-center'},
						'-' + parseFloat(d.price_reduction).toFixed(2)
					),
						
				])

			})
		} catch(e) { 
		    console.log('53');
		}

		console.log('discounts processed', discounts)

		this.props.order.sessions.forEach(sess => {
			let start_time = new Date(sess.start);
			
			sessions.push(
				React.createElement('div',{className:'row'}, 
					React.createElement('div',{className:'col-6'},
						React.createElement('div',{className:''},sess.title)//,
						//React.createElement('div',{className:''},start_time.toUTCString())
						
					),
					React.createElement('div',{className:'col-2 padding-lr-5 text-center'},
						sess.quantity+''
					),
					React.createElement('div',{className:'col-2 padding-lr-5 text-center'},
						sess.price.toFixed(2)+''
					),
					React.createElement('div',{className:'col-2 padding-lr-5 text-center'},
						(parseFloat(parseFloat(sess.price).toFixed(2)) * sess.quantity).toFixed(2)+''
					),
					discounts,
					React.createElement('div',{className:'col-12'},
						React.createElement('div',{className:''},start_time.toLocaleString("en-UK", {timeZone: tz}))
					),
				)
			)
		});

		console.log(this.props.order.discounts);




		let order = React.createElement('div',{className:'card mb-card rem08'},
					React.createElement('div',{className:'card-header'}, 
						React.createElement('div',{className:'row'}, 
							//React.createElement('div',{className:'container-fluid'}, 
								React.createElement('div',{className: 'date col-12 col-md-4'}, 'Time & Date:'),
								React.createElement('div',{className: 'date col-12 col-md-8'}, date.toLocaleString("en-UK", {timeZone: tz}))
								
							//)
						),
						React.createElement('div',{className:'row'}, 
							//React.createElement('div',{className:'container-fluid'}, 
								React.createElement('div',{className: 'reference col-12 col-md-4'}, 'Reference:'),
								
								React.createElement('div',{className: 'reference col-12 col-md-8'}, this.props.order.custom_id)
							//)
						)
						
					),
					React.createElement('div',{className:'card-body'},
						React.createElement('div',{className:'row'}, 
						 	React.createElement('div',{className:'col-5 col-md-4'}, 'Total:'),
						 	React.createElement('div',{className:'col-7 col-md-8'}, '£' + parseFloat(this.props.order.total).toFixed(2))

						),
						React.createElement('div',{className:'row'}, 
						 	React.createElement('div',{className:'col-5 col-md-4'}, 'Pay Ref.:'),
						 	React.createElement('div',{className:'col-7 col-md-8'}, this.props.order.order_reference)
						 	
						),
						/*
						React.createElement('div',{className:'row'}, 
						 	React.createElement('div',{className:'col-12 mb-card'}, 'Sessions')
						),
						*/

						React.createElement('div',{className:'row mb-card margin-top-5'}, 
						 	React.createElement('div',{className:'col-6'}, 'Title'),
						 	React.createElement('div',{className:'col-2 padding-lr-5 text-center'}, 'Qty'),
						 	React.createElement('div',{className:'col-2 padding-lr-5 text-center'}, '£'),
						 	React.createElement('div',{className:'col-2 padding-lr-5 text-center'}, 'ST'),
						),


						sessions
					)
				);

		return order;

	}
}

export default Order;