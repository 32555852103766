import React from 'react'
import ReactDOM from 'react-dom'

import SideNav from '../src/sidenav.js'
import SideNavFixed from '../src/sidenavfixed.js'
import Nav from '../src/nav.js'

import Popup from '../src/popup.js'
import Basket from '../src/basket.js'

import Footer from '../src/footer.js'

import {Helmet} from "react-helmet";

class Link extends React.Component {
	constructor(props) {
		
		super(props);
		
		this.state = {
			
		}
	}

	componentDidMount() {
			
	}

	render() {
		
		//let l = "http://www.keepandshare.com/doc12/view.php?id=" + this.props.link + '&da=y';
		//id=253162&da=y
		let l = 'https://drive.google.com/file/d/'+ this.props.link + '/preview';
		let link = React.createElement('iframe', {className:'maxh maxw', src:l, allow:"autoplay"});

		return (
			
			link
		)
	}

}

export default Link;