import React from 'react'
import ReactDOM from 'react-dom'
import Form from 'react-bootstrap/Form'

import SideNav from '../sidenav.js'
import SideNavFixed from '../sidenavfixed.js'
import { Redirect } from "react-router-dom";
import Nav from '../nav.js'

import Popup from '../popup.js'
import Basket from '../basket.js'
import Swal from 'sweetalert2'

import { Link } from "react-router-dom"
import {Helmet} from "react-helmet";
import Footer from '../footer.js'

class ForgotPassword extends React.Component {

	constructor() {
		super();
		this.updateField = this.updateField.bind(this);
		this.submitLogin = this.submitLogin.bind(this);

		this.state = {
			email: '',
			redirect: false,
			isLoading: false
		}
	}

	updateField(value, field) {
		let state = $.extend({},true,this.state);
		state[field] = value;
		this.setState(state);
	}

	submitLogin() {
		this.setState({
			isLoading: true
		});

		$.ajax({
		    url: '/users/password',
		    type: "POST",
		    data: {
		    	user: {
		    		email:this.state.email
		    	}	
		    },
		    beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
		    success: (data) => {
		    	console.log('data',data);
		    	this.setState({
		    		isLoading:false
		    	});

		    	Swal.fire({
					icon: 'success',
					title: 'Reset instructions sent - please check your emails.',
					showConfirmButton: true,
					timer: 1500
				}).then((result) => {  					
					window.location.href = '/sign_in';
				});
		    },
		    error: (error) => {
		        console.log('Error occurred!',error);
		    }
		});
	}

	render() {

		let openBasket = React.createElement(Basket, {
			user: this.props.user,
			basketOpen: this.props.basketOpen,
			basket_count: this.props.basket_count,
			basket: this.props.basket,
			id_map: this.props.id_map,
			toggleBasket: this.props.toggleBasket,
			linkString: ((this.props.user)?'Go to Checkout':'Log in before checking out'),
			link: ((this.props.user)?'/pay':'/sign_in')
		});


		let login_form = React.createElement(Form, {}, 
			React.createElement(Form.Group, {className: 'text-center', controlId: 'cl_email'},
				React.createElement(Form.Label, {}, 'Enter email address'),
				React.createElement(Form.Control, {
					value:this.state.email, 
					defaultValue: this.state.email,
					onChange: (e) => { this.updateField(e.target.value,'email') 
				}})
			),
			
			React.createElement('div',{className: 'container button-container'},
				React.createElement('div',{className: 'row justify-content-center'},
					
					React.createElement('div',{className: 'btn btn-primary', onClick: () => { this.submitLogin({
						email: this.state.email
					}) }}, 'Send me reset instructions'),
				)
			)
		);



		let sidenav = React.createElement(SideNav,{display:this.props.sidenav, sign_out: this.props.sign_out, user:this.props.user, toggleSideNav:this.props.toggleSideNav});
		let nav = React.createElement(Nav,{toggleSideNav:this.props.toggleSideNav, sign_out: this.props.sign_out, user:this.props.user, basket:this.props.basket, basket_count:this.props.basket_count, toggleBasket:this.props.toggleBasket});
		let page = React.createElement('div',{},
			React.createElement('div',{className:'row no-gutters sortScroll'},
				React.createElement('div',{className:'d-xl-block d-lg-block d-md-block d-none col-lg-2 col-md-3 mht bs'},
					React.createElement(SideNavFixed,{sign_out: this.props.sign_out, user: this.props.user})
				),
				React.createElement('div',{className:'pl-3 pr-3 pt-20 pb-20 col-12 col-md-9 offset-lg-1 col-lg-8 mht'},
					React.createElement('div',{className:'col-10 offset-1 col-sm-8 offset-sm-2 col-md-6 offset-md-3'},
						React.createElement('div',{className:'row justify-content-center'},
							React.createElement('h6',{className:'text-center'},
								'Forgot your password?'
							)
						),

						login_form

					)
				),
				React.createElement(Footer, {})
			)
		);

		let footer = null;

		let loading = null;

		if (this.state.isLoading) {
			let spinner = React.createElement('div',{className: 'loadingParent'},
				React.createElement('div',{className:'loader'}),
				React.createElement('div',{className:'loaderText'},'Loading...')
			);
			loading = React.createElement(Popup, {content: spinner, blockDismiss: true, noScroll: true, makeCenter:true});
		}

		let helmet = React.createElement(Helmet, {},
        	React.createElement('title',{}, 'Inclusive Online Activities'),
        	React.createElement('meta',{name:'description', content:'Reset your password to book online sessions for adults with learning disabiities'})
        )

		if (!this.state.redirect) {

			return (
				React.createElement('div',{className:'no-overflow'}, helmet, loading,nav,sidenav,page,footer,openBasket)
			)
		} else {
			return React.createElement(Redirect,{ to:'/'});
		}
		



	}
}

export default ForgotPassword;