import React from 'react'
import ReactDOM from 'react-dom'
import Navbar from 'react-bootstrap/Navbar'
import { Link } from "react-router-dom"

class SideNavFixed extends React.Component {
	
	constructor(props) {
		super(props);

		this.state = {
			
		};
	}

	render() {
		
		let content = [
			React.createElement(Link,{key:'snf1', to: '/', className:'row'}, 	
				React.createElement('div',{className: 'container-fluid'},
					React.createElement('div',{className: 'container-fluid padding-top-8 padding-bottom-8 hover-select ' + ((
							window.location.href.endsWith('com') ||
							window.location.href.endsWith('com/') ||
							window.location.href.endsWith('3000') || 
							window.location.href.endsWith('3000/') )?'navSelect':'')},'Home')
				)
			),
			React.createElement(Link,{key:'snf2_sessions', to: '/sessions', className:'row'}, 	
				React.createElement('div',{className: 'container-fluid'},
					React.createElement('div',{className: 'container-fluid padding-top-8 padding-bottom-8 hover-select ' + ((window.location.href.includes('sessions'))?'navSelect':'')},'Sessions')
				)
			),
			((this.props.user)? 
				React.createElement(Link,{key:'snf3', to: '/orders/all', className:'row'}, 	
					React.createElement('div',{className: 'container-fluid'},
						React.createElement('div',{className: 'container-fluid padding-top-8 padding-bottom-8 hover-select ' + ((window.location.href.includes('orders'))?'navSelect':'')},'Orders')
					)
				)
				:
				null
			),
			((this.props.user)? 
				React.createElement(Link,{key:'snf4', to: '/account', className:'row'}, 	
					React.createElement('div',{className: 'container-fluid'},
						React.createElement('div',{className: 'container-fluid padding-top-8 padding-bottom-8 hover-select ' + ((window.location.href.includes('account'))?'navSelect':'')},'Activities & Account')
					)
				)
				:
				null 
			),
			((this.props.user && this.props.user.admin)? 
				React.createElement('div',{key:'snf7', onClick: () => { window.location.href = '/admin'; }, className:'row'}, 	
					React.createElement('div',{className: 'container-fluid'},
						React.createElement('div',{className: 'container-fluid padding-top-8 padding-bottom-8 hover-select '},'Admin')
					)
				)
				:
				null 
				
			),
			React.createElement(Link,{key:'snf2_pages', to: '/pages', className:'row'}, 	
				React.createElement('div',{className: 'container-fluid'},
					React.createElement('div',{className: 'container-fluid padding-top-8 padding-bottom-8 hover-select ' + ((window.location.href.includes('pages'))?'navSelect':'')},'Tips Pages')
				)
			),
			((this.props.user) ?
					React.createElement('div',{key:'snf5', className:'row'}, 	
						React.createElement('div',{className: 'container-fluid'},
							React.createElement('div',{className: 'container-fluid padding-top-8 padding-bottom-8 hover-select'},


								React.createElement('div',{className:' w100', onClick: () => { this.props.sign_out() }}, 'Log Out')
							)
						)
					)
				
				:
					/*
					React.createElement('div',{ className:'row'}, 	
						React.createElement('div',{className: 'container-fluid'},
							React.createElement('div',{className: 'container-fluid padding-top-8 padding-bottom-8 hover-select'},


								React.createElement('div',{className:'w100', onClick: () => { window.location.href = '/users/sign_in' }}, 'Log In')
							)
						)
					)
					*/
					React.createElement(Link,{key:'snf7', to: '/sign_in', className:'row'}, 	
						React.createElement('div',{className: 'container-fluid'},
							React.createElement('div',{className: 'container-fluid padding-top-8 padding-bottom-8 hover-select ' + ((
							window.location.href.endsWith('/sign_in') )?'navSelect':'')},'Log in')
						)
					)
			)
			
		]
		//let exit = React.createElement('div',{className: 'exit'},String.fromCharCode(10006),'asdjkl');
		
		let sidenavfixed = React.createElement('div',{className: 'sidenavfixed padding-top-10'},content);
		return (
			
			React.createElement('div',{className: ''},sidenavfixed)
		)
	}
}

export default SideNavFixed;