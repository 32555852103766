import React from 'react'
import ReactDOM from 'react-dom'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'

import SideNav from '../src/sidenav.js'
import SideNavFixed from '../src/sidenavfixed.js'
import Nav from '../src/nav.js'
import DateView from '../src/home/dateView.js'
import SessionView from '../src/home/sessionView.js'
import Popup from '../src/popup.js'
import Basket from '../src/basket.js'

import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2'

import {Helmet} from "react-helmet";
import Footer from '../src/footer.js'

//const PayPalButton = paypal.Buttons.driver("react", { React, ReactDOM });


class Pay extends React.Component {
	constructor() {
		super();
		this.createOrder = this.createOrder.bind(this);
		this.onApprove = this.onApprove.bind(this);
		this.justBook = this.justBook.bind(this);
		this.updateField = this.updateField.bind(this);
		this.applyDiscountCode = this.applyDiscountCode.bind(this);

		this.state = {
			isLoading: false,
			custom_id:null,
			discount_code: '',
			discount_code_desc: '',
			discount_code_error: '',
			discounts:[],
			price_reduction:0
		}
	}

	componentDidMount() {
		this.props.checkBasketValid(true);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.basket_count == this.props.basket_count) {
			return true
		} else if (this.props.basket_count < 1 ) {			
			Swal.fire({
				title: 'Basket is empty',
				text: 'Add items to basket in order to checkout',
				confirmButtonText: 'Continue',
				icon: 'warning',
				
				preConfirm: (confirm) => {
					if (confirm) {
						window.location.href = '/sessions';
						return false;
					} else {
						return false;
					}
				}
			})
			return false;
		} else {
			return true
		}

	}

	updateField(value, field) {
		let state = $.extend({},true,this.state);
		state[field] = value;
		this.setState(state);

	}

	applyDiscountCode() {
		console.log('apply discount code');
		if (this.state.discount_code.length < 1) {
			return false
		}
		this.setState({
			isLoading:true,
			discounts:[]
		});
		$.ajax({
		    url: '/home/applyDiscountCode',
		    type: "POST",
		    contentType: "application/json",
		    data: JSON.stringify({
		    	basket: this.props.basket,
		    	user: this.props.user,
		    	total: this.state.total,
		    	discount_code: this.state.discount_code
		    }),
		    beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
		    success: (data) => {
				let discounts = this.state.discounts.concat([]);
				discounts.push(
		    		{
		    			name:this.state.discount_code,
		    			price_reduction: data.discount
		    		}
		    	)
		    	this.setState({
		    		isLoading:false,
		    		discounts: discounts
		    	});
		    },
		    error: (error) => {
		        console.log('Error occurred!', error);
		        this.setState({
		        	isLoading:false,
		        	discount_code_error: error.responseJSON.message
		        });

		        Swal.fire(
		        	"Code not allowed",
		        	error.responseJSON.message,
		        	"error"
		        )
		    }
		});
	}

	justBook() {
		let order_id = uuidv4();
		
		let total = 0;
		let basket_items = [];
		let compressed = [];

		this.props.basket.forEach(i => {
			if (compressed.indexOf(i) < 0) {
				compressed.push(i);
			}
			total += this.props.id_map[''+i].price;
		});

		this.setState({
			custom_id: order_id,
			total: total
		});


		$.ajax({
		    url: '/home/bookClasses',
		    type: "POST",
		    contentType: "application/json",
		    data: JSON.stringify({
		    	basket: this.props.basket,
		    	user: this.props.user,
		    	total: this.state.total,
		    	order_id: order_id.substr(order_id.length - 12),
		    	custom_id: order_id,
		    	discounts: this.state.discounts
		    }),
		    beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
		    success: (data) => {
		    	
		    	let self = this;
				window.localStorage.setItem('basket','');
				self.props.emptyBasket();

				Swal.fire({
					icon: 'success',
					title: 'Success',
					text: 'Order completed - reference '+ order_id.substr(order_id.length - 12) + '\n Please check your emails.',
					showConfirmButton: true
				}).then((results) => {
					window.location.href = '/sessions';
				});

		    },
		    error: (error) => {
		        console.log('Error occurred!', error);
		        let self = this;

		        Swal.fire(
					'Error completing order',
					error.responseJSON.message,
					'error'
				);
		            
	            self.setState({
	            	isLoading: false
	            });
	            
	            if (error.responseJSON.code == 400 && error.responseJSON.hasOwnProperty('basket')) {
	            	self.props.setBasket(error.responseJSON.basket.join(','));	
	            }

	            self.props.getUserAndSessions();
		    }
		});
	}

	createOrder(data, actions) {

		let order_id = uuidv4();
		
		let item_total = 0;
		let basket_items = [];
		let compressed = [];

		this.props.basket.forEach(i => {
			if (compressed.indexOf(i) < 0) {
				compressed.push(i);
			}
			item_total += this.props.id_map[''+i].price;
		});

		let discount_total = 0;

		this.state.discounts.forEach(d => {
			discount_total += d.price_reduction;
		});

		let total = item_total - discount_total;

		this.setState({
			custom_id: order_id,
			total: total
		});

		compressed.forEach(t => {
			let spaces = this.props.basket.filter(function(x){ return x === t.toString(); }).length
			let item = {
				name: this.props.id_map[''+t].title,
				unit_amount: {
					currency_code: 'GBP',
					value: this.props.id_map[''+t].price
				},
				quantity: spaces
			};
			basket_items.push(item);
		});

		let breakdown = {
            item_total: {
                currency_code: "GBP",
                value: item_total
            }
        }

        if (discount_total > 0) {
        	breakdown['discount'] = {
                currency_code: "GBP",
                value: discount_total
            }
        }

		let purchase_units = [
			{
        		custom_id: order_id,
        		reference_id: order_id,
        		application_context: { shipping_preference: 'NO_SHIPPING' },
        		description: 'Inclusive Online Activities Booking',
          		amount: {
          			currency_code: 'GBP',
            		value: total,
            		breakdown: breakdown
          		},
          		items: basket_items
        	}
		]

	    return actions.order.create({
	      	purchase_units: purchase_units
	    });
	 }

    onApprove(data, actions) {
    	//return false
    	this.setState({
    		isLoading:true
    	});

    	console.log('Approving payment');

    	$.ajax({
		    url: '/home/bookClasses',
		    type: "POST",
		    contentType: "application/json",
		    data: JSON.stringify({
		    	basket: this.props.basket,
		    	user: this.props.user,
		    	total: this.state.total,
		    	discounts: this.state.discounts,
		    	order_id: data.orderID,
		    	custom_id: this.state.custom_id
		    }),
		    beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
		    success: (data) => {

		    	console.log('Booking success now capture payment.');
		    	
		    	let self = this;

		    	return actions.order.capture().then(function(details) {
					
					console.log('Payment capture success', details);	
					window.localStorage.setItem('basket','');
					self.props.emptyBasket();


					Swal.fire({
						icon: 'success',
						title: 'Success',
						text: 'Order completed - reference '+ details.id + '\n Please check your emails.',
						showConfirmButton: true
					}).then((results) => {
						window.location.href = '/sessions';
					});
		            
		        });

		    },
		    error: (error) => {
		        console.log('Error occurred!', error);
		        let self = this;

		        Swal.fire(
					'Error completing order',
					error.responseJSON.message,
					'error'
				);
		            
	            self.setState({
	            	isLoading: false
	            });
	            
	            if (error.responseJSON.code == 400 && error.responseJSON.hasOwnProperty('basket')) {
	            	self.props.setBasket(error.responseJSON.basket.join(','));	
	            }

	            self.props.getUserAndSessions();
		    }
		});

    }

	render() {
		const PayPalButton = paypal.Buttons.driver("react", { React, ReactDOM });

		let openBasket = React.createElement(Basket, {
			basketOpen: this.props.basketOpen,
			basket_count: this.props.basket_count,
			basket: this.props.basket,
			id_map: this.props.id_map,
			toggleBasket: this.props.toggleBasket,
			linkString: 'Return to Classes',
			link: '/sessions'
		});

		let basket_items = [];
		let total = 0;
		let saving = 0;

		if (this.props.basket_count == 0) {
			basket_items = React.createElement('div',{className:'no-items-container'},
				React.createElement('div',{className:'no-items'},'No sessions in basket yet')
			)
		} else {
			let compressed = [];
			
			this.props.basket.forEach(i => {
				if (compressed.indexOf(i) < 0) {
					compressed.push(i);
				}
				total += this.props.id_map[''+i].price;
			});
			

			this.state.discounts.forEach(d => {
				
				total -= d.price_reduction;
				saving += d.price_reduction;
			});			


			let days = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
			let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];

			compressed.forEach(t => {

				let d = new Date(this.props.id_map[''+t].start);
				let s = days[d.getDay()] + ' ' + d.getDate() + ' ' + months[d.getMonth()] + ' at ' + d.getHours() + ':' + (d.getMinutes() < 10? '0' : '') + d.getMinutes();

				let mySpaces = this.props.basket.filter(function(x){ return x === t.toString(); }).length

				let item = this.props.id_map[''+t];

				let myCount = this.props.basket.filter(x => x == item.id).length;
				let spaces = item.spaces - item.booked_spaces - myCount;

				let spaces_text = '';
				if (spaces == 0 && myCount > 0) {
					spaces_text = "You've got the last space!";
				} else if (spaces == 0) {
					spaces_text = "No spaces left";
				} else if (spaces > 1 && spaces < 4) {
					spaces_text = spaces + ' spaces remaining';
				} else if (spaces == 1) {
					spaces_text = "Only " + spaces + " space remaining";
				}

				basket_items.push(
					React.createElement('div',{className:'basket_session padding-top-5 padding-bottom-5 row'},
						React.createElement('div',{className:'col-7 col-md-8 col-lg-9'},
							React.createElement('div',{className:'title col-12 padding-lr-0'}, this.props.id_map[''+t].title),
							React.createElement('div',{className:'title col-12 padding-lr-0'},s),
							React.createElement('div',{className:'price col-12 padding-lr-0'},'£'+this.props.id_map[''+t].price.toFixed(2))
						),
						React.createElement('div',{className:'col-5 col-md-4 col-lg-3 num df mh-28 padding-l-0 ' }, 
							React.createElement('div',{className:'container-fluid'},
								React.createElement('div',{className:'row space-between'},		

									React.createElement('div',{className:'btn btn-primary pb-0 pt-0', onClick:() => { 
										
										this.props.removeFromBasket(t, this.applyDiscountCode);
									}}, '-'),
									React.createElement('div',{className:'d-none d-sm-block'},
										mySpaces + ((mySpaces > 1 )? ' spaces' : ' space')
									),
									React.createElement('div',{className:'d-block d-sm-none'},
										mySpaces 
									),
									React.createElement('div',{className:'btn ' + ((spaces > 0)?'btn-primary':'btn-secondary disabled') + ' pb-0 pt-0', onClick:() => { 
										if (spaces > 0) {
											this.props.addToBasket(t, this.applyDiscountCode);
										}
									}}, '+')
								),
								React.createElement('div',{className:'row'},
									React.createElement('div',{className:'w-100 spaces text-center ' + ((spaces < 2)?'sad_booking':'happy_booking')}, 
										spaces_text
									)
									
								)
							)
						)
					)
				);
			});
		}
		
		let addDiscountCode = null;
		if (this.props.user.free_session) {

		} else if (this.state.discounts.length > 0) {
			addDiscountCode = [];
			this.state.discounts.forEach(d => {
				addDiscountCode.push(React.createElement('div',{className:'discountCode'}, d.name + ' code applied. (£' + saving.toFixed(2) + ' saved)'));
			});

		} else {
			addDiscountCode = [React.createElement(Form.Group,{className:'margin-top-20'}, 
				React.createElement(InputGroup, {className:'mb-3'},
					React.createElement(Form.Control, {
						value:this.state.discount_code, 
						defaultValue: this.state.discount_code,
						placeholder: 'Discount Code',
						onChange: (e) => { this.updateField(e.target.value,'discount_code') }
					}),
					React.createElement('div',{
						className:'btn ' + ((this.state.discount_code.length == 0)?'btn-secondary':'btn-purple'),
						onClick: () => {this.applyDiscountCode()}
					},'Apply')
				)			
			)];

			//code failed - show message


		}
		

		let basket_total = React.createElement('div',{className:'total row padding-bottom-5'}, 
			React.createElement('div',{className:'divider padding-top-5 col-12'}),
			React.createElement('div',{className:'col-7 col-md-8 col-lg-9'}, 'Total:'),
			React.createElement('div',{className:'col-5 col-md-4 col-lg-3 text-center padding-l-0'},'£'+total.toFixed(2))
		)

		let paypalDivs = null;
		let justBook = null;

		if (total > 0) {
			paypalDivs = React.createElement('div',{className:'text-align-center margin-top-20'},
				React.createElement(PayPalButton, { 
					createOrder:(data, actions) => this.createOrder(data, actions),
	        		onApprove:(data, actions) => this.onApprove(data, actions)
	        	})
	        );
        	
		} else {
			justBook = React.createElement('div',{className:'bookButton text-align-center'},
				React.createElement('div',{className:'btn btn-primary', onClick: () => { this.justBook() }}, 'Make Booking')
			);
		}

		let pay_page = React.createElement('div',{className: 'paypage'},
			React.createElement('h5',{className:'title text-align-center'},'Checkout'),
			basket_items,
			addDiscountCode,
			basket_total,
			paypalDivs,
			justBook
		);

		let sidenav = React.createElement(SideNav,{display:this.props.sidenav, sign_out: this.props.sign_out, user:this.props.user, toggleSideNav:this.props.toggleSideNav});
		let nav = React.createElement(Nav,{toggleSideNav:this.props.toggleSideNav, sign_out: this.props.sign_out, user:this.props.user, basket:this.props.basket, basket_count:this.props.basket_count, toggleBasket:this.props.toggleBasket});
		let page = React.createElement('div',{},
			
			React.createElement('div',{className:'row no-gutters sortScroll'},
				React.createElement('div',{className:'d-xl-block d-lg-block d-md-block d-none col-lg-2 col-md-3  mht bs'},
					React.createElement(SideNavFixed,{sign_out: this.props.sign_out, user: this.props.user})
				),
				React.createElement('div',{className:'pl-3 pr-3 pt-20 col-12 col-md-9 offset-lg-1 col-lg-8 mht'},
					pay_page

				),
				React.createElement(Footer, {})
			)
		);

		let loading = null;

		if (this.state.isLoading) {
			let spinner = React.createElement('div',{className: 'loadingParent'},
				React.createElement('div',{className:'loader'}),
				React.createElement('div',{className:'loaderText'},'Loading...')
			);
			loading = React.createElement(Popup, {content: spinner, blockDismiss: true, noScroll: true, makeCenter:true});
		}

		let helmet = React.createElement(Helmet, {},
        	React.createElement('title',{}, 'Pay - Inclusive Online Activities'),
        	React.createElement('meta',{name:'description', content:'Pay for your sessions with Inclusive Online Activities'})
        )

		return (
			
			React.createElement('div',{className:'no-overflow'},loading, nav,sidenav,page,openBasket)
		)
	}
}

export default Pay;