import React from 'react'
import ReactDOM from 'react-dom'

class SessionItem extends React.Component {
	render() {
		let sessionContent = React.createElement('div',{className:'sessionItem'},'session item');

		return  React.createElement('div',{className:'sessionItemContainer'},sessionContent);
	}
}

export default SessionItem