import React from 'react'
import ReactDOM from 'react-dom'
import Form from 'react-bootstrap/Form'

import SideNav from '../src/sidenav.js'
import SideNavFixed from '../src/sidenavfixed.js'
import Nav from '../src/nav.js'

import Popup from '../src/popup.js'
import Basket from '../src/basket.js'

import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Swal from 'sweetalert2'

import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";
import Footer from '../src/footer.js'

class Page extends React.Component {
	constructor() {
		super();
		

		this.state = {
			isLoading: true,
			page: {
				title: '',
				content:''
			}
		}
	}

	componentDidMount() {
		$.ajax({
		    url: '/home/getPage',
		    type: "POST",
		    contentType: "application/json",
		    data: JSON.stringify({
		    	url:this.props.url
		    }),
		    beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
		    success: (data) => {
		    	console.log('data',data);
		    	this.setState({
		    		isLoading: false,
		    		page: data.page
		    	});
		    },
		    error: (error) => {
		        console.log('Error occurred!', error);
		        this.setState({
		    		isLoading: false
		    	});
		    }
		});		
	}

	
	render() {

		let openBasket = React.createElement(Basket, {
			user: this.props.user,
			basketOpen: this.props.basketOpen,
			basket_count: this.props.basket_count,
			basket: this.props.basket,
			id_map: this.props.id_map,
			toggleBasket: this.props.toggleBasket,
			linkString: ((this.props.user)?'Go to Checkout':'Log in before checking out'),
			link: ((this.props.user)?'/pay':'/sign_in')
		});


		

		

		let sidenav = React.createElement(SideNav,{display:this.props.sidenav, sign_out: this.props.sign_out, user:this.props.user, toggleSideNav:this.props.toggleSideNav});
		let nav = React.createElement(Nav,{toggleSideNav:this.props.toggleSideNav, sign_out: this.props.sign_out, user:this.props.user, basket:this.props.basket, basket_count:this.props.basket_count, toggleBasket:this.props.toggleBasket});
		let page = React.createElement('div',{},
			
			React.createElement('div',{className:'row no-gutters sortScroll'},
				React.createElement('div',{className:'d-xl-block d-lg-block d-md-block d-none col-lg-2 col-md-3 mht bs'},
					React.createElement(SideNavFixed,{sign_out: this.props.sign_out, user: this.props.user})
				),
				
				React.createElement('div',{className:'pl-3 pr-3 pt-20 pb-20 col-12 col-md-9 offset-lg-1 col-lg-8 mht blog'},
					React.createElement('div',{className:'padding-bottom-20'},
						React.createElement(Link,{className:'', to:'/pages'},'Back to all tips pages')
					),
					React.createElement('h2',{className:'text-center'},
						this.state.page.title
					),
					React.createElement('div',{className:'row '}, 
					 	React.createElement('div',{className:'col-12'}, 
					 		React.createElement('div',{className:'card-match-width', dangerouslySetInnerHTML:{__html: this.state.page.content }})
					 	)
					),
					React.createElement('div',{className:'pt-20'},
						React.createElement(Link,{className:'', to:'/pages'},'Back to all tips pages')
					),
					

				),
				React.createElement(Footer, {})
				
			)

		);

		let helmet = React.createElement(Helmet, {},
        	React.createElement('title',{}, this.state.page.title),
        	React.createElement('meta',{name:'description', content:this.state.page.title})
        )


		return (
			
			React.createElement('div',{className:'no-overflow'}, helmet, nav,sidenav,page,openBasket)
		)
	}

}

export default Page;