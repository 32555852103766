import React from 'react'
import ReactDOM from 'react-dom'
import Popup from '../src/popup.js'
import { Link } from "react-router-dom";


console.log('pay page');

class Basket extends React.Component {
	constructor() {
		
		super();
		
	}



	render() {

		let openBasketContent = null;
		let openBasket = null;
		let total = 0;
		if (this.props.basketOpen) {
			let basket_items = [];
			if (this.props.basket_count == 0) {
				basket_items = React.createElement('div',{className:'no-items-container'},
					React.createElement('div',{className:'no-items'},'No sessions in basket yet')
				)
			} else {
				let compressed = [];
				
				this.props.basket.forEach(i => {
					if (compressed.indexOf(i) < 0) {
						compressed.push(i);
					}
					total += this.props.id_map[''+i].price;
				})
				let days = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
				let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];



				compressed.forEach(t => {
					
					let d = new Date(this.props.id_map[''+t].start);
					let s = days[d.getDay()] + ' ' + d.getDate() + ' ' + months[d.getMonth()] + ' at ' + d.getHours() + ':'+ (d.getMinutes() < 10? '0' : '') + d.getMinutes();

					let mySpaces = this.props.basket.filter(function(x){ return x === t.toString(); }).length

					let item = this.props.id_map[''+t];

					let myCount = this.props.basket.filter(x => x == item.id).length;
					let spaces = item.spaces - item.booked_spaces - myCount;
					let spaces_text = '';
					if (spaces == 0 && myCount > 0) {
						spaces_text = "You've got the last space!";
					} else if (spaces == 0) {
						spaces_text = "No spaces left";
					} else if (spaces > 1 && spaces < 4) {
						spaces_text = spaces + ' spaces remaining';
					} else if (spaces == 1) {
						spaces_text = "Only " + spaces + " space remaining";
					}
					basket_items.push(
						React.createElement('div',{className:'basket_session padding-top-5 padding-bottom-5 row'},
							React.createElement('div',{className:'col-7 col-lg-8'},
								React.createElement('div',{className:'title col-12 padding-lr-0'}, this.props.id_map[''+t].title),
								React.createElement('div',{className:'title col-12 padding-lr-0'},s),
								React.createElement('div',{className:'price col-12 padding-lr-0'},'£'+this.props.id_map[''+t].price.toFixed(2))
							),
							React.createElement('div',{className:'col-5 col-lg-4 df num mh-28 padding-l-0'}, 
								React.createElement('div',{className:'container-fluid'},
									React.createElement('div',{className:'row space-between'},
										React.createElement('div',{className:'btn btn-primary pb-0 pt-0', onClick:() => { 
											this.props.removeFromBasket(t) 
										}}, '-'),
										React.createElement('div',{className:'d-none d-sm-block'},
											mySpaces + ((mySpaces > 1 )? ' spaces' : ' space')
										),
										React.createElement('div',{className:'d-block d-sm-none'},
											mySpaces 
										),
										React.createElement('div',{className:'btn ' + ((spaces > 0)?'btn-primary':'btn-secondary disabled') + ' pb-0 pt-0', onClick:() => { 
											if (spaces > 0) {
												this.props.addToBasket(t);
											}
										}}, '+')
									),
									React.createElement('div',{className:'row'},
								
								
									

										
										React.createElement('div',{className:'w-100 spaces text-center ' + ((spaces < 2)?'sad_booking':'happy_booking')}, 
											spaces_text
											
										)
									
									)
								)

							)
						)
					);
				});
			}

			let basket_total = React.createElement('div',{className:'total row padding-bottom-5'}, 
				React.createElement('div',{className:'divider padding-top-5 col-12'}),
				React.createElement('div',{className:'col-7 col-lg-8'}, 'Total:'),
				React.createElement('div',{className:'col-5 col-lg-4 text-center padding-l-0'},'£'+total.toFixed(2))
			)

			let basket_checkout = React.createElement('div',{className:'go_checkout justify-content-center row padding-top-5'},
				/*
				((this.props.link == '/users/sign_in')?
					React.createElement('div', {onClick: () => { window.location.href = this.props.link }, className:'btn btn-primary'},this.props.linkString)
					:
					React.createElement(Link, {to: this.props.link, className:'btn btn-primary'},this.props.linkString)
				)
				*/
				React.createElement(Link, {to: this.props.link, className:'btn btn-primary', onClick: () => { this.props.toggleBasket() }},this.props.linkString)
				
			)

			openBasketContent = React.createElement('div',{className:'row'},
				React.createElement('div',{className:'container'},
					React.createElement('div',{className: 'popout_header row'}, 'Basket'),
					basket_items,
					basket_total,
					basket_checkout
				)
			);
			openBasket = React.createElement(Popup,{content:openBasketContent, closePopup: () => { this.props.toggleBasket() } })

		}
		
		return (
			
			React.createElement('div',{className:'no-overflow'},openBasket)
		)
	}
}

export default Basket;