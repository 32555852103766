import React from 'react'
import ReactDOM from 'react-dom'
import Form from 'react-bootstrap/Form'

import SideNav from '../src/sidenav.js'
import SideNavFixed from '../src/sidenavfixed.js'
import Nav from '../src/nav.js'

import Popup from '../src/popup.js'
import Basket from '../src/basket.js'

import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Swal from 'sweetalert2'

import { Link } from "react-router-dom";

import {Helmet} from "react-helmet";

import Footer from '../src/footer.js'

class Pages extends React.Component {
	constructor() {
		super();
		

		this.state = {
			isLoading: true,
			pages: []
		}
	}

	componentDidMount() {
		$.ajax({
		    url: '/home/getPages',
		    type: "POST",
		    contentType: "application/json",
		    data: JSON.stringify({
		    	
		    }),
		    beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
		    success: (data) => {
		    	console.log('data',data);
		    	this.setState({
		    		isLoading: false,
		    		pages: data.pages
		    	});
		    },
		    error: (error) => {
		        console.log('Error occurred!', error);
		        this.setState({
		    		isLoading: false
		    	});
		    }
		});		
	}

	
	render() {

		let openBasket = React.createElement(Basket, {
			user: this.props.user,
			basketOpen: this.props.basketOpen,
			basket_count: this.props.basket_count,
			basket: this.props.basket,
			id_map: this.props.id_map,
			toggleBasket: this.props.toggleBasket,
			linkString: ((this.props.user)?'Go to Checkout':'Log in before checking out'),
			link: ((this.props.user)?'/pay':'/sign_in')
		});


		let pages = [];


		this.state.pages.forEach(p => {
			
			pages.push(
				React.createElement('div',{className:'card mb-card rem1'},
					React.createElement('div',{className:'card-header'}, 
						React.createElement('div',{className:'row'}, 
								React.createElement('h4',{className: 'date col-12 rem12'}, p.title)
						)
					),
					React.createElement('div',{className:'card-body blog'},
						React.createElement('div',{className:'row maxh-150 overflow-hidden mask-image'}, 
						 	React.createElement('div',{className:'col-12', dangerouslySetInnerHTML:{__html: p.content }})

						),
						React.createElement('div',{className:'text-center'},
							React.createElement(Link, {to: '/pages/'+p.url, rel: 'canonical'}, 'Read more')
						)
					)
				)
			);
		});

		

		let sidenav = React.createElement(SideNav,{display:this.props.sidenav, sign_out: this.props.sign_out, user:this.props.user, toggleSideNav:this.props.toggleSideNav});
		let nav = React.createElement(Nav,{toggleSideNav:this.props.toggleSideNav, sign_out: this.props.sign_out, user:this.props.user, basket:this.props.basket, basket_count:this.props.basket_count, toggleBasket:this.props.toggleBasket});
		let page = React.createElement('div',{},
			
			React.createElement('div',{className:'row no-gutters sortScroll'},
				React.createElement('div',{className:'d-xl-block d-lg-block d-md-block d-none col-lg-2 col-md-3 mht bs'},
					React.createElement(SideNavFixed,{sign_out: this.props.sign_out, user: this.props.user})
				),
				
				React.createElement('div',{className:'pl-3 pr-3 pt-20 pb-20 col-12 col-md-9 offset-lg-1 col-lg-8 mht pages'},
					React.createElement('h4',{className:'text-center'},
						'Tips Pages*'
					),
					React.createElement('div',{className:'card-match'},
						'If you or someone you care for has a learning disability, it can be tricky to navigate the world and get help for things that you need. Whilst we do not claim to be experts, we have a lot of personal experience in fighting for adults with learning disabilities, and ',
						'have tried to put together some helpful pages below. We hope they are useful!'
					),
					pages,
					React.createElement('p',{className:'text-center padding-top-20'},
						'*The content of these pages is based on personal experience and is in no way meant to be taken as official. If anything is found to be incorrect please let us know at admin@inclusiveonlineactivities.com'
					),
					

				),
				React.createElement(Footer, {})
				
			)

		);


        let helmet = React.createElement(Helmet, {},
        	React.createElement('title',{}, 'Tips Pages - Inclusive Online Activities'),
        	React.createElement('meta',{name:'description', content:'Tips on how to navigate work and work experience for adults with learning disabilities.'})
        )

		return (
			
			React.createElement('div',{className:'no-overflow'},helmet, nav,sidenav,page,openBasket)
		)
	}

}

export default Pages;