import React from 'react'
import ReactDOM from 'react-dom'
import Navbar from 'react-bootstrap/Navbar'
import Button from 'react-bootstrap/Button'
import { Link } from "react-router-dom";

class Nav extends React.Component {
	
	constructor() {
		super();

		this.state = {

		};
	}

	render() {

		let icon = React.createElement('svg',{
				width:"1em", 
				height:"1em",
				viewBox:"0 0 16 16",
				className:"bi bi-list",
				fill:"white",
				xmlns:"http://www.w3.org/2000/svg"
			},
			React.createElement('path',{
				fillRule:"evenodd",
				d:"M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
			})
		)

		let shopping_icon = React.createElement('svg',{
				width:"1.3em", 
				height:"1.3em",
				viewBox:"0 0 16 16",
				className:"bi bi-minecart-loaded",
				fill:"white",
				xmlns:"http://www.w3.org/2000/svg"
			},
			React.createElement('path',{
				fillRule:"evenodd",
				d:"M4 15a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 1a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm8-1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 1a2 2 0 1 0 0-4 2 2 0 0 0 0 4zM.115 3.18A.5.5 0 0 1 .5 3h15a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 14 12H2a.5.5 0 0 1-.491-.408l-1.5-8a.5.5 0 0 1 .106-.411zm.987.82l1.313 7h11.17l1.313-7H1.102z"
			}),
			React.createElement('path',{
				fillRule:"evenodd",
				d:"M6 1.5a2.498 2.498 0 0 1 4 0c.818 0 1.545.394 2 1 .67 0 1.28.265 1.729.694l-.692.722A1.493 1.493 0 0 0 12 3.5c-.314 0-.611-.15-.8-.4-.274-.365-.71-.6-1.2-.6-.314 0-.611-.15-.8-.4a1.497 1.497 0 0 0-2.4 0c-.189.25-.486.4-.8.4-.507 0-.955.251-1.228.638a2.65 2.65 0 0 1-.634.634 1.511 1.511 0 0 0-.263.236l-.75-.662a2.5 2.5 0 0 1 .437-.391 1.63 1.63 0 0 0 .393-.393A2.498 2.498 0 0 1 6 1.5z"
			})
		)



		
		let nav = React.createElement(Navbar, {className:'bg-dark space-between h-60'},
			
				React.createElement('div',{className:'nav-icon d-md-none', onClick: () => { this.props.toggleSideNav()} },icon),
				
				React.createElement(Link, {className: 'col-white hov-col-white', to:'/', href:'/home'}, 
					React.createElement('img', {className:'maxh-30 padding-right-5 d-none d-sm-inline-block', alt:'Inclusive Online Activities Cubes Icon', src:'/logo.webp'}),
					React.createElement('div',{className:'col-white hov-col-white height-30 display-inline-block'},'Inclusive Online Activities')
				),
				React.createElement('div',{className:'basket', onClick: () => { this.props.toggleBasket() }},
					React.createElement('div',{className:'basket_num'},(this.props.basket_count || 0)),
					React.createElement('img',{'src':'/basket.svg', alt:'Basket Icon', className:'basket_item'})
				)
				
		)
		return (
			
			React.createElement('div',{className: ''},nav)
		)
	}
}

export default Nav;