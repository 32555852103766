import React from 'react'
import ReactDOM from 'react-dom'
import Form from 'react-bootstrap/Form'

import SideNav from '../src/sidenav.js'
import SideNavFixed from '../src/sidenavfixed.js'
import Nav from '../src/nav.js'

import Popup from '../src/popup.js'
import Basket from '../src/basket.js'

import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Swal from 'sweetalert2'

import {Helmet} from "react-helmet";

import Footer from '../src/footer.js'

class Account extends React.Component {
	constructor() {
		super();
		this.updateField = this.updateField.bind(this);
		this.submitPasswordUpdate = this.submitPasswordUpdate.bind(this);
		this.deleteAccount = this.deleteAccount.bind(this);

		this.state = {
			isLoading: true,
			old_password: '',
			password: '',
			password_confirmation:'',
			uc_sessions: []
		}
	}

	componentDidMount() {
		$.ajax({
		    url: '/home/getUserUpcomingSessions',
		    type: "POST",
		    contentType: "application/json",
		    data: JSON.stringify({
		    	user: this.props.user
		    }),
		    beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
		    success: (data) => {
		    	console.log('data',data);
		    	this.setState({
		    		isLoading: false,
		    		uc_sessions: data.sessions
		    	});
		    },
		    error: (error) => {
		        console.log('Error occurred!', error);
		        this.setState({
		    		isLoading: false
		    	});
		    }
		});		
	}

	updateField(value, field) {
		let state = $.extend({},true,this.state);
		state[field] = value;
		this.setState(state);

	}

	deleteAccount() {
		Swal.fire({
			title: 'Are you sure?',
			text: 'This cannot be undone',
			showCancelButton: true,
			confirmButtonText: 'Continue',
			icon: 'warning',
			reverseButtons: true,
			
			preConfirm: (confirm) => {
				if (confirm) {
					$.ajax({
					    url: '/user/deleteAccount',
					    type: "POST",
					    data: {
					    	
					    },
					    beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
					    success: (data) => {
					    	console.log('data',data);

					    	Swal.fire({
								icon: 'success',
								title: 'Account deleted',
								showConfirmButton: true,
								timer: 1500
							}).then((result) => {  					
								window.location.href = '/';
							});;

					    	
					    	
					    },
					    error: (error) => {
					        console.log('Error occurred!',error);
					        Swal.fire(
								'Error deleting account',
								error.responseJSON.message,
								'error'
							);
					    }
					});
				}
			}
		})
	}

	submitPasswordUpdate() {

		$.ajax({
		    url: '/user/changePassword',
		    type: "POST",
		    data: {
		    	user: {
		    		old_password: this.state.old_password,
		    		password:this.state.password,
		    		password_confirmation:this.state.password_confirmation,
		    	}	
		    },
		    beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
		    success: (data) => {
		    	console.log('data',data);

		    	Swal.fire({
					icon: 'success',
					title: 'Password changed successfully',
					showConfirmButton: true,
					timer: 1500
				});

		    	this.setState({
		    		password: '',
		    		password_confirmation: '',
		    		old_password: ''
		    	});
		    	
		    },
		    error: (error) => {
		        console.log('Error occurred!',error);
		        Swal.fire(
					'Error updating password',
					error.responseJSON.message,
					'error'
				);
		    }
		});
	}

	render() {

		let openBasket = React.createElement(Basket, {
			user: this.props.user,
			basketOpen: this.props.basketOpen,
			basket_count: this.props.basket_count,
			basket: this.props.basket,
			id_map: this.props.id_map,
			toggleBasket: this.props.toggleBasket,
			linkString: ((this.props.user)?'Go to Checkout':'Log in before checking out'),
			link: ((this.props.user)?'/pay':'/sign_in')
		});


		let uc_sessions = [];


		this.state.uc_sessions.forEach(sess => {
			let start_time = new Date(sess.start);
			let tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
			uc_sessions.push(
				React.createElement('div',{className:'card mb-card rem08'},
					React.createElement('div',{className:'card-header'}, 
						React.createElement('div',{className:'row'}, 
								React.createElement('div',{className: 'date col-5 col-md-4'}, 'Title'),
								React.createElement('div',{className: 'date col-7 col-md-8'}, sess.title)
						)
					),
					React.createElement('div',{className:'card-body'},
						React.createElement('div',{className:'row'}, 
						 	React.createElement('div',{className:'col-5 col-md-4'}, 'When:'),
						 	React.createElement('div',{className:'col-7 col-md-8'}, start_time.toLocaleString("en-UK", {timeZone: tz}))

						),
						React.createElement('div',{className:'row'}, 
						 	React.createElement('div',{className:'col-5 col-md-4'}, 'Zoom Link:'),
						 	React.createElement('a',{href: sess.zoom_join_link, target:'_blank', className:'col-7 col-md-8'}, sess.zoom_join_link)
						 	
						),
						((sess.file && sess.file.length > 0)?
							React.createElement('div',{className:'row'}, 
							 	React.createElement('div',{className:'col-5 col-md-4'}, ((sess.category == 'Cooking')?'Recipe:':'Info Sheet:')),
							 	React.createElement('a',{target: '_blank', href: '/file/'+sess.file, className:'col-7 col-md-8'}, 'Link')
							 	
							):null
						)
					)
				)
			);
		});

		let accordion = React.createElement(Accordion, {},
			React.createElement(Card,{},
				React.createElement(Card.Header, {},
					React.createElement(Accordion.Toggle, {as: Button, variant:"link", eventKey:"0"},'Change Password')
				),
				React.createElement(Accordion.Collapse, {eventKey:"0"},
					React.createElement(Card.Body, {}, 
						
						React.createElement(Form.Group, {className: 'text-center col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3', controlId: 'current_pw'},
							React.createElement(Form.Label, {}, 'Current password'),
							React.createElement(Form.Control, {
								value:this.state.old_password, 
								defaultValue: this.state.old_password,
								type: 'password',
								onChange: (e) => { this.updateField(e.target.value,'old_password') 
							}})
						),
						React.createElement(Form.Group, {className: 'text-center col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3', controlId: 'cl_pw'},
							React.createElement(Form.Label, {}, 'New password (min length 6)'),
							React.createElement(Form.Control, {
								value:this.state.password, 
								defaultValue: this.state.password,
								type: 'password',
								onChange: (e) => { this.updateField(e.target.value,'password') 
							}})
						),
						React.createElement(Form.Group, {className: 'text-center col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3', controlId: 'cl_pw_conf'},
							React.createElement(Form.Label, {}, 'New password confirmation'),
							React.createElement(Form.Control, {
								value:this.state.password_confirmation, 
								defaultValue: this.state.password_confirmation,
								type: 'password',
								onChange: (e) => { this.updateField(e.target.value,'password_confirmation') 
							}})
						),
						
						React.createElement('div',{className: 'container button-container'},
							React.createElement('div',{className: 'row justify-content-center'},
								
								React.createElement('div',{className: 'btn btn-primary', onClick: () => { this.submitPasswordUpdate({
									
								}) }}, 'Reset Password'),
							)
						)
					)
				)
			),
			React.createElement(Card,{},
				React.createElement(Card.Header, {},
					React.createElement(Accordion.Toggle, {as: Button, variant:"link", eventKey:"1"},'Delete Account')
				),
				React.createElement(Accordion.Collapse, {eventKey:"1"},
					React.createElement(Card.Body, {}, 
						React.createElement('div',{className:''}, 'Deleting your account will mean that you are no longer able to log in, book or attend sessions. '+ 
							'Some of your info will be kept for our records such as previous order information. Your email address will no longer be kept on record.'),
						React.createElement('div',{className:'btn btn-danger text-center margin-lr-5 margin-bottom-5 mt-20', onClick: () => {this.deleteAccount()}},
							'Delete Account'
						)
					)
				)
			)
		)

		let sidenav = React.createElement(SideNav,{display:this.props.sidenav, sign_out: this.props.sign_out, user:this.props.user, toggleSideNav:this.props.toggleSideNav});
		let nav = React.createElement(Nav,{toggleSideNav:this.props.toggleSideNav, sign_out: this.props.sign_out, user:this.props.user, basket:this.props.basket, basket_count:this.props.basket_count, toggleBasket:this.props.toggleBasket});
		let page = React.createElement('div',{},
			
			React.createElement('div',{className:'row no-gutters sortScroll'},
				React.createElement('div',{className:'d-xl-block d-lg-block d-md-block d-none col-lg-2 col-md-3 mht bs'},
					React.createElement(SideNavFixed,{sign_out: this.props.sign_out, user: this.props.user})
				),
				
				React.createElement('div',{className:'pl-3 pr-3 pt-20 pb-20 col-12 col-md-9 offset-lg-1 col-lg-8 mht'},
					React.createElement('h4',{className:'text-center'},
						'Upcoming Booked Sessions'
					),
					uc_sessions,
					React.createElement('h4',{className:'text-center mt-40'},
						'Change Account Info'
					),

					accordion,

/*
					React.createElement('div',{className: 'row'}, 
						React.createElement('div',{className: 'container-fluid text-align-center'}, 
							React.createElement('div',{className:'btn btn-primary text-center margin-lr-5 margin-bottom-5'},
								'Change Password'
							),
							
							React.createElement('div',{className:'btn btn-danger text-center margin-lr-5 margin-bottom-5'},
								'Delete Account'
							)
						)
					)
*/					
					

				),
				React.createElement(Footer, {})
				
			)

		);

		let helmet = React.createElement(Helmet, {},
        	React.createElement('title',{}, 'Account - Inclusive Online Activities'),
        	React.createElement('meta',{name:'description', content:'Account Page - see upcoming sessions and account details'})
        )


		return (
			
			React.createElement('div',{className:'no-overflow'},nav,sidenav,page,openBasket)
		)
	}

}

export default Account;