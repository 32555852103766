import React from 'react'
import ReactDOM from 'react-dom'

class Missing extends React.Component {
	render() {
		
		return  React.createElement('div',{className: ''}, 'missing route');
					
	}
}

export default Missing