import React from 'react'
import ReactDOM from 'react-dom'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'

import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'

import SideNav from '../src/sidenav.js'
import SideNavFixed from '../src/sidenavfixed.js'
import Nav from '../src/nav.js'
import DateView from '../src/home/dateView.js'
import SessionView from '../src/home/sessionView.js'
import Popup from '../src/popup.js'
import Basket from '../src/basket.js'
import Footer from '../src/footer.js'

import Swal from 'sweetalert2'

import Carousel from 'react-bootstrap/Carousel'

import { Link } from "react-router-dom";

import {Helmet} from "react-helmet";
import ReactPlayer from "react-player";
//const PayPalButton = paypal.Buttons.driver("react", { React, ReactDOM });

class Home extends React.Component {
	constructor() {
		super();

		this.launchPopup = this.launchPopup.bind(this);
		this.updateField = this.updateField.bind(this);
		this.submitIdea = this.submitIdea.bind(this);
		this.vidControls = this.vidControls.bind(this);
		this.handleSelect = this.handleSelect.bind(this);

		this.state = {
			idea: '',
			idea_popup_vis: false,
			vidControls: false,
			activeIndex: 0

		}
	}

	updateField(value, field) {
		let state = $.extend({},true,this.state);
		state[field] = value;
		this.setState(state);
	}

	componentDidMount() {

	}

	launchPopup(s) {
		this.setState({
			idea_popup_vis: s
		});
	}

	submitIdea(idea) {
		console.log('submit idea',idea);
		this.setState({
			idea_popup_vis: false,
			idea: ''
		});

		$.ajax({
			url: '/home/suggestIdea',
			type: "POST",
			contentType: "application/json",
			data: JSON.stringify({
				idea: idea
			}),
			beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
		    success: (data) => {
		    	
		    	Swal.fire(
		        	'Thank you!',
		        	"We'll have a think if it's something we can include.",
		        	'success'
		        );
		        
		    },
		    error: (error) => {
		        
		        console.log('Error submitting idea.');

		    }
		});


	}

	vidControls() {
		this.setState({
			vidControls: true
		});
	}

	handleSelect(si, e) {
		console.log('handleSelect', si, e);
		//let ai = this.state.activeIndex;
		this.setState({
			activeIndex: si
		})
	}

	render() {

		let openBasket = React.createElement(Basket, {
			user: this.props.user,
			basketOpen: this.props.basketOpen,
			basket_count: this.props.basket_count,
			basket: this.props.basket,
			id_map: this.props.id_map,
			toggleBasket: this.props.toggleBasket,
			addToBasket: this.props.addToBasket,
			removeFromBasket: this.props.removeFromBasket,
			linkString: ((this.props.user)?'Go to Checkout':'Log in before checking out'),
			link: ((this.props.user)?'/pay':'/sign_in')
		});

		let ph = React.createElement('div', {className:'vh-100-t'},
			React.createElement('div',{className:'item-1 '},
				React.createElement('div',{className:'container-fluid'},
					React.createElement('div',{className:'caption row justify-content-center'},
						React.createElement('h1',{className:'col-10 mb095 fw-700 fs-34'}, 'Online activities for adults with learning disabilities'),
						React.createElement('div',{className:'col-12'}),
						React.createElement(Link,{to:'/sessions', className:'btn btn-light'}, 
							React.createElement('h2',{className:'col-800080 fs-34 fw-700'}, 'Find a session')
						)
					)
				)
			)
		);
		
		let wwd = React.createElement('div',{className:'hiw row ml-15 mr-15 padding-bottom-0'},
					React.createElement('div',{className:'col-12 col-sm-6 col-md-4 home-item-cont'}, 
						React.createElement('div',{className:'home-item col-12'},
							React.createElement('div',{className:'title'}, 
								React.createElement('h2',{className: 'col-800080 text-align-center fw-700'},'What we do')
							),
							React.createElement('div',{className:'content'},
								React.createElement('div',{className:'text-align-center content-text'},
									"We run online interactive sessions covering lots of different activities. Our focus is to have fun and a good time! We love to see our attendees growing in confidence and trying new things. "
								)
							)
						)
					),
					React.createElement('div',{className:'col-12 col-sm-6 col-md-4 home-item-cont'}, 
						React.createElement('div',{className:'home-item col-12 '},
							React.createElement('div',{className:'title'}, 
								React.createElement('h2',{className: 'col-800080 text-align-center fw-700'},"Who would enjoy it")
							),
							React.createElement('div',{className:'content'},
								React.createElement('div',{className:'text-align-center content-text'},
									"Our activities are enjoyed by adults with learning disabilities, and sometimes older teenagers. If you are unsure if you or someone you care for would find our sessions suitable, please give us a ring to discuss!"
								)
							)
						)
					),
					React.createElement('div',{className:'col-12 col-sm-6 col-md-4 home-item-cont'}, 
						React.createElement('div',{className:'home-item col-12'},
							React.createElement('div',{className:'title'}, 
								React.createElement('h2',{className: 'col-800080 text-align-center fw-700'},'Activities on offer')
							),
							React.createElement('div',{className:'content'},
								React.createElement('div',{className:'text-align-center content-text'},
									"The sessions cover a variety of interests including dance, music, signing, and other special interest topics! All are designed to be as inclusive as possible with experienced leaders (a minimum of two on any session). If you would like any information on any activity please email us."
								)
							)
						)
					),
					React.createElement('div',{className:'col-12 col-sm-6 col-md-4 home-item-cont'}, 
						React.createElement('div',{className:'home-item col-12'},
							React.createElement('div',{className:'title'}, 
								React.createElement('h2',{className: 'col-800080 text-align-center fw-700'},'How it works')
							),
							React.createElement('div',{className:'content'},
								React.createElement('div',{className:'text-align-center content-text'},
									"After booking an activity, you will be sent a confirmation email that includes a Zoom link that will enable you to join your session five minutes before it starts. An email reminder will also be sent with the Zoom link shortly beforehand. If you need any equipment (for example, paper for an art session) this will be highlighted in the activity description."
								)
							)
						)
					),
					React.createElement('div',{className:'col-12 col-sm-6 col-md-4 home-item-cont'}, 
						React.createElement('div',{className:'home-item col-12 '},
							React.createElement('div',{className:'title'}, 
								React.createElement('h2',{className: 'col-800080 text-align-center fw-700'},'What you need')
							),
							React.createElement('div',{className:'content'},
								React.createElement('div',{className:'text-align-center content-text'},
									"As all our sessions are run using Zoom video conferencing software, you will need a device (preferably a laptop or computer) that will enable you to see what's going on and join in! Zoom can be downloaded ", React.createElement('a',{href: 'https://zoom.us/download', target: '_blank'}, 'here'), "."
								)
							)
						)
					),
					React.createElement('div',{className:'col-12 col-sm-6 col-md-4 home-item-cont'}, 
						React.createElement('div',{className:'home-item col-12'},  
							React.createElement('div',{className:'title'}, 
								React.createElement('h2',{className: 'col-800080 text-align-center fw-700'},'First Session Free')
							),
							React.createElement('div',{className:'content'},
								React.createElement('div',{className:'text-align-center content-text'},
									"If you haven't booked with us before, use code ",
									React.createElement('b',{className:'fw-700'},'FIRSTFREE'),
									" at checkout to get your first session free!"
								)
							)
						)
					)
				);

		
		let gs = React.createElement('div',{className:'hiw row ml-15 mr-15 bg-light-purple maxw'},

				React.createElement('div',{className:'gs col-12'},
					React.createElement('div',{className:'title'}, 
						React.createElement('h2',{className: 'col-800080 text-align-center fw-700'},'Steps to book a session')
					),
				),
				
				React.createElement('div',{className:'col-12 col-sm-6 col-md-4 home-item-gs'}, 
					React.createElement('div',{className:'home-item col-12'},
						React.createElement('div',{className:'title'}, 
							React.createElement('h2',{className: 'col-800080 text-align-center fw-700 fs-20'},'1. Sign up/Log in')
						),
						((this.props.user)?
							React.createElement('div',{className:'content'},
								React.createElement('div',{className:'text-align-center content-text '},
									"If you haven't already, create an account with us - or log in if you already have an account."
								)
							)
							:
							React.createElement('div',{className:'content'},
								React.createElement('div',{className:'text-align-center content-text'},
									"Start off by creating your account ",
									React.createElement(Link,{to:'/sign_up', className:''},'here'),
									". This will take you to our sign up page. Alternatively, if you already have an account then log in ",
									React.createElement(Link,{to:'/sign_in', className:''},'here'),
									"."
								)
							)
						)
						
					)
				),
				React.createElement('div',{className:'col-12 col-sm-6 col-md-4 home-item-gs'}, 
					React.createElement('div',{className:'home-item col-12'},
						React.createElement('div',{className:'title'}, 
							React.createElement('h2',{className: 'col-800080 text-align-center fw-700 fs-20'},'2. Find a session')
						),
						React.createElement('div',{className:'content'},
							React.createElement('div',{className:'text-align-center content-text'},
								"Have a look through our activities. When you've found one you would like to attend, click on the ",
								React.createElement('div',{className:'btn btn-primary2 btn-disabled-primary', disabled: true},'Add to basket'),
								" button."
								
							)
						)
					)
				),
				React.createElement('div',{className:'col-12 col-sm-6 col-md-4 home-item-gs'}, 
					React.createElement('div',{className:'home-item col-12'},
						React.createElement('div',{className:'title'}, 
							React.createElement('h2',{className: 'col-800080 text-align-center fw-700 fs-20'},'3. Open basket')
						),
						React.createElement('div',{className:'content'},
							React.createElement('div',{className:'text-align-center content-text'},
								"In the top right hand corner - the basket should now show that you have added an activity. ",
								//React.createElement('img',{className:'', src:'/activity_in_basket.webp'}),
								
								React.createElement('div',{className:'basket-display bg-dark'},
									React.createElement('div',{className:'basket-num-display'},1),
									React.createElement('img',{'src':'/basket.svg', alt:'Basket Icon', className:'basket_item'})
								),
								" Click on it to open your basket."
								
							)
						)
					)
				),
				React.createElement('div',{className:'col-12 col-sm-6 col-md-4 home-item-gs'}, 
					React.createElement('div',{className:'home-item col-12'},
						React.createElement('div',{className:'title'}, 
							React.createElement('h2',{className: 'col-800080 text-align-center fw-700 fs-20'},'4. Go to checkout')
						),
						React.createElement('div',{className:'content'},
							React.createElement('div',{className:'text-align-center content-text'},
								'Next, click on the "Go to Checkout" button'
								
							),
							React.createElement('div',{className:'gtc text-align-center'},
								React.createElement('img',{className: 'gtc-image', src: 'go_to_checkout.webp'})
							)
						)
					)
				),
				React.createElement('div',{className:'col-12 col-sm-6 col-md-4 home-item-gs'}, 
					React.createElement('div',{className:'home-item col-12'},
						React.createElement('div',{className:'title'}, 
							React.createElement('h2',{className: 'col-800080 text-align-center fw-700 fs-20'},'5. Pay')
						),
						React.createElement('div',{className:'content'},
							React.createElement('div',{className:'text-align-center content-text'},
								'On the pay page, you should see the options to pay using Paypal, or, if the sessions you want to book are free, a ',
								React.createElement('div',{className:'btn btn-primary2 btn-disabled-primary'},'Make Booking'),
								' button.'
							),
							
						)
					)
				),
				React.createElement('div',{className:'col-12 col-sm-6 col-md-4 home-item-gs'}, 
					React.createElement('div',{className:'home-item col-12'},
						React.createElement('div',{className:'title'}, 
							React.createElement('h2',{className: 'col-800080 text-align-center fw-700 fs-20'},'6. Receive email')
						),
						((this.props.user)?
						React.createElement('div',{className:'content'},
							React.createElement('div',{className:'text-align-center content-text'},
								'Once you have booked, you should receive an email with your session details and your zoom links to join the activities. You can also see your upcoming sessions and find the zoom links in the "',
								React.createElement(Link,{to:'/account', className:''},'Activities & Account'),
								'" area.'
							)
						)
						:
						React.createElement('div',{className:'content'},
							React.createElement('div',{className:'text-align-center content-text'},
								'Once you have booked, you should receive an email with your session details and your zoom links to join the activities. You can also see your upcoming sessions and find the zoom links in the "',
								'Activities & Account',
								'" area.'
							)
						)
						)
					)
				)

			
		)

		let guag = React.createElement('div',{className:'hiw row ml-15 mr-15 maxw'},
			
				React.createElement('div',{className:'gs col-12'},
					React.createElement('div',{className:'title'}, 
						React.createElement('h2',{className: 'col-800080 text-align-center fw-700'},'Why give us a try')
					)
				),
				React.createElement('div',{className:'gs col-12'},
					React.createElement('div',{className:'home-item col-12'},
						React.createElement('div',{className:'content'},
							React.createElement('div',{className:'text-align-center'},
								'We do what we do because we love connecting with people with learning disabilities and having fun! ',
								'There can be many reasons why adults with special needs can become isolated, but not being able to attend events in person ',
								'can be a big factor. We feel connecting virtually online can be really important and enjoyable - and all from the comfort of home. ',
								React.createElement('br',{}),
								'Some of our attendees have been able to really get to know each other through the sessions, ',
								'which has been great to see. We encourage you to connect with us on facebook as well - you can find us using the links at the bottom of our website.',
								React.createElement('br',{}),
								React.createElement(Link,{to:'/sessions', className:'text-align-center'},'See our activities'),
								)
						)
					)
				)


		)

		let reviewReel = React.createElement('div',{className:'hiw row ml-15 mr-15 bg-800080 minh-150 w100 justify-content-center'},
			React.createElement('div',{className:'row fs-12 minw-100 justify-content-center'},
				"5 Star Facebook Review Score!"
			),
			React.createElement('div',{className:'row minw-100 justify-content-center', alt: '5 Start Facebook Review Score'},
				React.createElement('img',{src:'star.webp', className:'maxh-60'}),
				React.createElement('img',{src:'star.webp', className:'maxh-60'}),
				React.createElement('img',{src:'star.webp', className:'maxh-60'}),
				React.createElement('img',{src:'star.webp', className:'maxh-60'}),
				React.createElement('img',{src:'star.webp', className:'maxh-60'}),
			),
			
			React.createElement(Carousel, {
				activeIndex:this.state.activeIndex,
				className:'minh-150 minw-100',
				onSelect:this.handleSelect  
			},
		      	
				React.createElement(Carousel.Item, {className:'minw-100 minh-150 padding-top-20'},
		      		
		        	React.createElement(Carousel.Caption,{className:'fs-18'},
		          		React.createElement('h3',{className:'fs-18'},"I want to tell you that when I hear L’s singing, drumming etc called brilliant or wonderful or amazing etc, it makes me cry. No one has ever said those wonderful words to anything she does."),
						React.createElement('h3',{className:'fs-18'},"My entire family appreciates this program."),
		          		//React.createElement('p',{},"Helen's Mum")
		          	)
		      	),

		      	React.createElement(Carousel.Item, {className:'minw-100 minh-150 padding-top-20'},
		      		
		        	React.createElement(Carousel.Caption,{className:'fs-18'},
		          		React.createElement('h3',{className:'fs-18'},"LM has gained so much confidence in herself and loves to perform like a Dancing Queen in her own way. It is so amazing to watch."),
						React.createElement('h3',{className:'fs-18'},"Our family are very grateful for all the wonderful things that you do."),
		          		//React.createElement('p',{},"Helen's Mum")
		          	)
		      	),
		      	
				React.createElement(Carousel.Item, {className:'minw-100 minh-150 padding-top-20'},
		        	React.createElement(Carousel.Caption,{},
		          		React.createElement('h3',{className:'fs-18'},"Inclusive online activities are a great opportunity to try something new, make friends and have fun."),
		          		//React.createElement('p',{},"Claire's Mum")
		          	)
		      	),
		      	React.createElement(Carousel.Item, {className:'minw-100 minh-150 padding-top-20'},
		      		
		        	React.createElement(Carousel.Caption,{},
		          		React.createElement('h3',{className:'fs-18'},"Taking part in these activities have been great fun for me. I love the Zoom sessions most of all. I highly recommend Inclusive Online Activities!"),
		          		//React.createElement('p',{},"Claire's Mum")
		          	)
		      	),




		      	  
 
		    )
		);
		  

		

		let suggestion = React.createElement('div',{className:'hiw row ml-15 mr-15 maxw bg-800080'},
			
				React.createElement('div',{className:'gs col-12'},
					React.createElement('div',{className:'title'}, 
						React.createElement('h2',{className: 'col-white text-align-center fw-700'},'Got a great activity idea?')
					)
				),
				React.createElement('div',{className:'gs col-12'},
					React.createElement('div',{className:'col-12'},
						React.createElement('div',{className:'content'},
							React.createElement('div',{className:'col-white text-align-center'},
								"We're always trying to think up new activity ideas that people would love to join in with. If you've got a ",
								"fantastic idea for a new session we'd love to hear about it! "
							)
						)
					)
				),
				/*
				React.createElement(Form.Group, {className:'col-12', controlId: 'idea'},
					React.createElement(Form.Control, {value:this.state.idea, onChange: (e) => { this.updateField(e.target.value,'idea') }})
				),
				*/
				React.createElement('div',{className:'text-align-center col-12 padding-bottom-20'},
					React.createElement('div',{className:'btn btn-primary',onClick:() => {this.launchPopup(true)}},'Suggest new activity')

				)


		)

		let popup = null;
		if (this.state.idea_popup_vis) {
			let pcontent = React.createElement('div',{className:'pcontent'},
				React.createElement(Form.Group, {className:'col-12 text-align-center', controlId: 'idea'},
					React.createElement(Form.Label, {}, "Tell us your great activity idea!"),
					React.createElement(Form.Control, {value:this.state.idea, onChange: (e) => { this.updateField(e.target.value,'idea') }})
				),
				React.createElement('div',{className:'text-align-center'},
					React.createElement('div',{className:'btn btn-primary',onClick:() => {
						this.submitIdea(this.state.idea)
					}},'Submit')

				)
			)
			popup = React.createElement(Popup, {
				content: pcontent,
				closePopup: () => {this.launchPopup(false)}
			});
		}


		let sidenav = React.createElement(SideNav,{display:this.props.sidenav, sign_out: this.props.sign_out, user:this.props.user, toggleSideNav:this.props.toggleSideNav});
		let nav = React.createElement(Nav,{toggleSideNav:this.props.toggleSideNav, sign_out: this.props.sign_out, user:this.props.user, basket:this.props.basket, basket_count:this.props.basket_count, toggleBasket:this.props.toggleBasket});
		let page = React.createElement('div',{},
			React.createElement('div',{className:'row hideScroll'},
				/*
				React.createElement('div',{className:'d-xl-block d-lg-block d-md-block d-none col-lg-2 col-md-3 mht bs'},
					React.createElement(SideNavFixed,{sign_out: this.props.sign_out, user: this.props.user})
				),
				React.createElement('div',{className:'pl-3 pr-3 pt-20 pb-20 col-12 col-md-9 offset-lg-1 col-lg-8 mht'},
					ph
				),
				*/
				
				ph,
				reviewReel,

				wwd,
				

				gs,
				guag,
				suggestion,
				React.createElement(Footer, {})
			)
		);

		let footer = null;

        let helmet = React.createElement(Helmet, {},
        	React.createElement('title',{}, 'Fun Online Zoom Activities for Adults with Learning Disabilities'),
        	React.createElement('meta',{name:'description', content:'We offer fun online activities for adults who have learning disabilities or who are disabled. Our sessions are run via Zoom and are as inclusive as possible.'})
        )



		return (
			
			React.createElement('div',{className:'no-overflow'}, helmet, nav,sidenav,page,footer,openBasket, popup)
		)
	}
}

export default Home;