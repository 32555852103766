import React from 'react'
import ReactDOM from 'react-dom'
import Form from 'react-bootstrap/Form'

class SessionView extends React.Component {
	
	constructor(props) {
		super(props);
		this.updateFilter = this.updateFilter.bind(this);

		this.state = {
			category:'All'
		}
	}

	updateFilter(category) {
		this.setState({
			category:category
		});
	}

	render() {

		let categories = {
			'Art':[],
			'Cooking':[],
			'Dance':[],
			'Music':[],
			'Special Interest':[]
		};

		this.props.sessions.forEach(s => {
			categories[s.category].push(s);
		});

		let t_cards = [];

		Object.keys(categories).forEach(i => {
			if (this.state.category == i || this.state.category == 'All') {
				let items = [];
				categories[i].forEach(item => {
					//console.log('item_id', item.id.toString(), (this.props.basket.indexOf(item.id.toString())));
					let d = new Date(item.start);
					

					let days = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
					let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];

					let s = days[d.getDay()] + ' ' + d.getDate() + ' ' + months[d.getMonth()] + ' at ' + d.getHours() + ':' + (d.getMinutes() < 10? '0' : '') + d.getMinutes();

					let myCount = this.props.basket.filter(x => x == item.id).length;
					let spaces = item.spaces - item.booked_spaces - myCount;
					let spaces_text = '';
					if (spaces == 0 && myCount > 0) {
						spaces_text = "You've got the last space!";
					} else if (spaces == 0) {
						spaces_text = "No spaces left";
					} else if (spaces > 1 && spaces < 4) {
						spaces_text = spaces + ' spaces remaining';
					} else if (spaces == 1) {
						spaces_text = "Only " + spaces + " space remaining";
					}

					let prebooked = null;
					if (this.props.user) {
						let attendees = item.attendees.split(',');
						var count = {};
						attendees.forEach(number => count[number] = (count[number] || 0) +1);

						if (count[this.props.user.id] > 0) {
							prebooked = React.createElement('div', {key:item.id+'sv_pb', className:'green px12'}, 
								'You have ' + count[this.props.user.id] + ' ' +
								((count[this.props.user.id] > 1)?'spaces':'space') + 
								' booked on this session'
							)
						}
					}

					items.push(
						React.createElement('div',{key:item.id+'cb_sv', className:'card-body mb-card'},
							React.createElement('h5',{key:item.id+'ct_sv_h5', className:'card-title margin-bottom-0'},item.title),
							React.createElement('div',{key:item.id+'cd_sv', className:'card-text'},item.description),
							React.createElement('div',{key:item.id+'ct_sv', className:'card-text'},'When: ' + s),
							React.createElement('div',{key:item.id+'cp_sv', className:'card-text'}, 'Price: £'+parseFloat(item.price).toFixed(2) + ' per person'),
							React.createElement('div',{key:item.id+'cbdu_sv', className:'card-text'}, 'Length: ' + item.duration + ' minutes'),
							((this.props.basket.indexOf(item.id.toString()) < 0)?
								React.createElement('div',{
									key:item.id+'bas_sv',
									className:'btn ' + ((spaces > 0)?'btn-primary':'btn-secondary disabled') + ' mt-8', 
									onClick:() => { 
										if (spaces > 0) {
											this.props.addToBasket(item.id);
										}
									}
								},'Add to basket')
								:
								React.createElement('div',{key:item.id+'cb_2_sv', className:'add mt-8'},
									React.createElement('div',{key:item.id+'rem_b_sv',  className:'btn btn-primary mr-10',onClick: () => {
										this.props.removeFromBasket(item.id)
									}},' - '),
									this.props.basket.filter(function(x){ return x === item.id.toString(); }).length + ' in basket ',
									React.createElement('div',{key:item.id+'add_b_sv',  className:'btn ' + ((spaces > 0)?'btn-primary':'btn-secondary disabled') + ' ml-10',onClick: () => {
										if (spaces > 0) {
											this.props.addToBasket(item.id);
										}
									}},' + ')
								)
							),
							React.createElement('div',{key:item.id+'cs_sv', className:'spaces ' + ((spaces < 2)?'sad_booking':'happy_booking')}, 
								spaces_text
							),
							prebooked
						)
					);
				});
				t_cards.push(
					React.createElement('div',{key: i+'_cat', className:'card mt-8'},
						React.createElement('h5',{key: i+'_cat_header', className:'card-header bg-light-purple '}, i),
						items
					)
				)	
			}
			
		});

		let category_selects = [
			React.createElement('option', {key: 'sv_cat_all', value: 'All'}, 'All')
		];

		Object.keys(categories).forEach(c => {
			category_selects.push(
				React.createElement('option', {key: c+'cat', value: c}, c)
			);	
		});

		let select = React.createElement(Form.Group, {key: 'sv_select', className:'row jc mt-20', controlId: 'cl_category'},
						React.createElement(Form.Label, {key: 'sv_label', className:'col-4 col-sm-3 offset-sm-1 col-md-3 offset-md-2 col-lg-2 offset-lg-3'}, 'Category'),
							React.createElement(Form.Control, {
								key: 'sv_selects',
								as: 'select', 
								className:'col-8 col-sm-7 col-md-5 col-lg-4 mr-15 ml--15',
								value:this.state.category, 
								defaultValue: this.state.category,
								onChange: (e) => { this.updateFilter(e.target.value) }
							}, 
								category_selects
							)
						);
				
		let sessionContent = React.createElement('div',{key: 'sv_dc_cont', className:'dateContent'},
			select,
			React.createElement('div',{key: 'sv_timecards', className:'timecards mt-8'}, t_cards)
		);
		
		

		return  React.createElement('div',{key: 'sv_sessCont', className:'sessionContainer'},sessionContent);
	}
}

export default SessionView